/** @jsx jsx */
import {jsx} from '@emotion/core'

import {Form, TreeSelect} from 'antd'

interface MetricProps {
  label: string
  dataSource: any[]
  stateKey: string
  state: any
  onChange: any
}

function MetricCustom({
                        label,
                        dataSource = [],
                        stateKey,
                        state,
                        onChange,
                      }: MetricProps) {
  return (
    <Form.Item label={label} style={{marginBottom: 0}}>
      <TreeSelect
        id={`metrics_${label}_select`}
        size="large"
        treeData={dataSource}
        value={state[stateKey]}
        onChange={(value: any) => onChange(stateKey, value)}
        treeCheckable={true}
        searchPlaceholder={'Please select'}
      />
    </Form.Item>
  )
}

export default MetricCustom
