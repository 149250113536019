/** @jsx jsx */
import {jsx} from '@emotion/core'

import {Spin} from 'antd'

interface LoadingSpinnerProps {}

function LoadingSpinner(props: LoadingSpinnerProps) {
  return <Spin size="large" style={{fontSize: '2em'}} />
}

export default LoadingSpinner
