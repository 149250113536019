import {capitalize} from './helper'

const API_URL = document.location.hostname === 'localhost'
  ? ""
  : `${document.location.protocol}//api.${document.location.hostname}${document.location.hostname.includes('local') ? ':3030': ''}`;

interface ClientArgs {
  method?: any
  body?: any
  customConfig?: any
}

const client = (
  endpoint: string,
  args: ClientArgs = {customConfig: {}},
  isTokenRequired = true,
) => {
  const {body, method, customConfig = {}} = args
  const token = window.localStorage.getItem('__inskin_reports_token__')
  const headers: any = {'content-type': 'application/json'}

  if (token) {
    headers.Authorization = `Bearer ${token}`
  } else {
    if (isTokenRequired) {
      throw new Error(`The session has expired, please login again - TEST.`)
    }
  }

  const config: any = {
    credentials: 'include',
    method: method || (body ? 'POST' : 'GET'),
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  return window
    .fetch(`${API_URL}/${endpoint}`, config)
    .then((r: any) => {
      if (r.status === 401) {
        throw new Error(`The session has expired, please login again.`)
      } else if (r.status !== 200 && r.status !== 201 && r.status !== 204) {
        return r.json().then((json: any) => {
          if (json.message === 'jwt expired') {
            throw new Error(`The session has expired, please login again.`)
          }

          console.log('json', json)
          const errors = [`Code: ${r.status}`]
          console.log('after:ping', json)
          if (json.errors && json.errors.length) {
            json.errors.forEach((e: any) => {
              errors.push(capitalize(e.show_message || e.message || e))
            })
            console.log('errors', errors)
            throw new Error(errors.join('|||'))
          } else if (json.message) {
            throw new Error(`${json.message} (Code: ${r.status})`)
          } else {
            throw new Error(`Error Code: ${r.status}`)
          }
        })
      }
      if (r.status !== 204) {
        return r.text()
      }
    })
    .then(text => {
      try {
        return JSON.parse(text);
      } catch(err) {
        return { response_text : text };
      }
    })
    .catch(e => {
      console.log('API Error', e)
      throw new Error(`${e.message}`)
    })
}

export { client, API_URL }
