import {client} from './api-client'

function search() {
  return client(`reports`)
}

function create(data: any) {
  return client(`reports`, {
    method: 'POST',
    body: data,
  })
}

function createAndWait(data: any, signal?: any) {
  const args: any = { method: 'POST', body: data };

  if (signal) {
    args.customConfig = { signal };
  }

  return client(`reports?waitForResult=1`, args)
}

function findOne(id: number | string) {
  return client(`reports/${id}`)
}

export const getPOReport = async ({year}: any) => {
  return client(`po/list?year=${year}`)
}

export {search, create, createAndWait, findOne}
