/** @jsx jsx */
import {jsx} from '@emotion/core'

import {Form, TreeSelect} from 'antd'

const data = [
  {
    title: 'All',
    value: 'creative',
    key: '0-3',
    children: [
      {
        title: 'Video',
        value: 'interaction_video',
        key: '0-3-0',
        children: [
          {
            title: 'Video Start',
            value: 'video_start',
            key: '0-3-0-1',
          },
          {
            title: 'Video 25%',
            value: 'video_25',
            key: '0-3-0-2',
          },
          {
            title: 'Video 50%',
            value: 'video_50',
            key: '0-3-0-3',
          },
          {
            title: 'Video 75%',
            value: 'video_75',
            key: '0-3-0-4',
          },
          {
            title: 'Video Complete',
            value: 'video_complete',
            key: '0-3-0-5',
          },
          {
            title: 'Video Replay',
            value: 'video_replay',
            key: '0-3-0-6',
          },
          {
            title: 'Video Unmute',
            value: 'video_unmute',
            key: '0-3-0-7',
          },
          {
            title: 'Video Click',
            value: 'video_click',
            key: '0-3-0-8',
          },
        ],
      },
      {
        title: 'VTR %',
        value: 'interaction_vtr',
        key: '0-3-1',
      },
    ],
  },
]

// TODO: Separate interface in local types.ts file
interface MetricProps {
  label?: string
  dataSource?: any[]
  stateKey: string
  state: any
  onChange: any
}

function MetricCreative({
  label,
  dataSource = [],
  stateKey,
  state,
  onChange,
}: MetricProps) {
  return (
    <Form.Item label={label || 'Video'} style={{marginBottom: 0}}>
      <TreeSelect
        size="large"
        treeData={dataSource.length ? dataSource : data}
        value={state[stateKey]}
        onChange={value => onChange(stateKey, value)}
        treeCheckable={true}
        searchPlaceholder={'Please select'}
      />
    </Form.Item>
  )
}

export default MetricCreative
