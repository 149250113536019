import React from 'react'
import {useAsync} from 'react-async'
import {bootstrapAppData} from '../utils/bootstrap'
import * as authClient from '../utils/auth-client'

const AuthContext = React.createContext({})

function AuthProvider(props: any) {
  const [firstAttemptFinished, setFirstAttemptFinished] = React.useState(false)
  const {
    cancel,
    data = {user: null, listItems: []},
    error = new Error(),
    isRejected,
    isPending,
    isSettled,
    reload,
  } = useAsync({
    promiseFn: bootstrapAppData,
  })

  React.useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true)
    }
    return () => {
      cancel()
    }
  }, [cancel, isSettled])

  if (!firstAttemptFinished) {
    if (isPending) {
      return <div>Loading...</div>
    }
    if (isRejected) {
      return (
        <div>
          <p>Uh oh... There's a problem. Try refreshing the app.</p>
          <pre>{error.message}</pre>
        </div>
      )
    }
  }

  const login = (form: any) => authClient.login(form).then(function(user) {
    if (user) {
      reload();
    }
  })
  const register = (form: any) => authClient.register(form).then(reload)
  const logout = (redirect?: string) =>
    authClient.logout().then(() => {
      if (redirect) {
        window.location.href = `${redirect.startsWith('http') ? '' : 'http://'}${redirect}`;
      } else {
        reload();
        window.location.href = '/';
      }
    })
  const googleLogin = () => authClient.googleLogin()
  const verifyGoogleToken = (token: string) =>
    authClient.verifyGoogleToken(token)

  return (
    <AuthContext.Provider
      value={{data, login, logout, register, googleLogin, verifyGoogleToken}}
      {...props}
    />
  )
}

function useAuth(): any {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export {AuthProvider, useAuth}
