/** @jsx jsx */
import {jsx} from '@emotion/core'

import FilterDate from 'src/filters/filter-date'
import {Fragment} from 'react'
import Filter from 'src/filters/filter'
import {Typography} from 'antd'
import MetricViewability from 'src/metrics/metric-viewability'
import MetricFinancial from 'src/metrics/metric-financial'
import MetricCreative from 'src/metrics/metric-creative'
import GroupByFields from 'src/group-by/group-by-fields'
import GroupByDate from 'src/group-by/group-by-date'
import MetricCustom from '../metrics/metric-custom'
import {
  commonValidateFiltersInputRules,
  isInskinAccount,
} from '../utils/helper'
import CreatorCampaignsSummary from './creator-campaigns-summary'
import * as genericClient from '../utils/generic-api'

const {Title} = Typography

class ImproveDigitalCampaignsSummary extends CreatorCampaignsSummary {
  constructor(id?: string, name?: string, url?: string, options?: any) {
    const merged_options = {filterDefaults: {
        campaigns: [],
        date_range: 'last_2_months',
      },
      groupByDefaults: {
        group_by: ['campaign_name', 'line_item_name'],
        date_group: 'lifetime',
      },
    }

    if (typeof options === 'object') {
      Object.assign(merged_options, options)
    }

    super(
      id || 'reports:improve-digital-campaigns-summary',
      name || 'Marketplace Campaigns Summary Report',
      url || 'improve-digital-campaigns-summary',
      merged_options
    )
  }

  static get className() {
    return 'ImproveDigitalCampaignsSummary';
  }

  getAvailableUsersCampaignsToShowInReport(user?: any) {
    if (isInskinAccount(user)) {
      return null;
    }
    return user.campaigns_raw.filter((campaign: any) => campaign.isImproveDigital);
  }

  checkIfReportHasInitialState(user?: any) {
    // return !isInskinAccount(user);
    return false;
  }

  get initialReportType() {
    // return 'campaigns-summary-dashboard';
    return undefined;
  }

  checkIfReportHasDataToFetch(user: any) {
    return isInskinAccount(user);
  }

  validateFiltersInputRules({filters}: any) {
    if (!filters.campaigns || filters.campaigns.length === 0) {
      return [
        'Please select at least one campaign from the list to continue',
      ]
    }

    return commonValidateFiltersInputRules({ filters });
  }

  async fetchFiltersData(signal: any): Promise<any> {
    let filtersData = {};

    try {
      filtersData = {
        campaigns: (await genericClient.search('campaigns?isImproveDigital=true&$order=-endDate', '', signal)).data,
      };
    } catch (e: any) {
      if (signal.aborted) {
        console.log('[fetchFiltersData] ABORTED!')
      }
    }

    return filtersData;
  }

  renderSidebar({
                  user,
                  filters,
                  filtersData,
                  metrics,
                  groupBy,
                  onChangeFilter,
                  onChangeMetric,
                  onChangeGroupBy,
                }: any) {

    return (
      <Fragment>
        <Title level={4} style={{marginTop: 20}}>
          General
        </Title>
        <FilterDate
          options={this.options}
          state={filters}
          onChange={onChangeFilter}
        />
        <Filter
          label={'Campaigns'}
          // url='campaigns?isCreator=true&$order=-id'
          stateKey={'campaigns'}
          state={filters}
          initialLoading={this.checkIfReportHasDataToFetch(user) ? !filtersData.campaigns : undefined}
          dataSource={this.checkIfReportHasDataToFetch(user) ? filtersData.campaigns : this.getAvailableUsersCampaignsToShowInReport(user)}
          onChange={onChangeFilter}
        />
        <Title level={4} style={{marginTop: 20}}>
          Metrics
        </Title>
        <MetricCustom
          label={'General:'}
          stateKey='general'
          dataSource={[{
            title: 'All',
            value: 'general',
            key: '0-0',
            children: [
              { title: 'Displayed Ad Impressions',
                value: 'displayed_impressions',
                key: '0-0-1' },
              { title: 'Invalid Impressions',
                value: 'invalid_impressions',
                key: '0-0-2' },
              { title: 'Clicks',
                value: 'clicks',
                key: '0-0-3' },
              { title: 'CTR %',
                value: 'ctr',
                key: '0-0-4' }
            ]
          }]}
          state={metrics}
          onChange={onChangeMetric}
        />
        <MetricViewability
          stateKey='viewability'
          state={metrics}
          onChange={onChangeMetric}
        />
        <MetricFinancial
          stateKey="financial"
          state={metrics}
          onChange={onChangeMetric}
        />
        <MetricCreative
          stateKey="creative"
          state={metrics}
          onChange={onChangeMetric}
        />
        <MetricCustom
          label={'Attention:'}
          stateKey='attention'
          dataSource={[{
            title: 'All',
            value: 'general',
            key: '0-0',
            children: [
              { title: '% of Total Impressions Looked At',
                value: 'pct_total_impressions_looked_at',
                key: '0-0-1' },
              { title: 'Avg. Visual Engagement Time',
                value: 'avg_visual_engagement_time',
                key: '0-0-2' },
              { title: 'Attention per 1000 Impressions',
                value: 'attention_per_1000_impressions',
                key: '0-0-3' },
              { title: 'Total Attention',
                value: 'total_attention_hours',
                key: '0-0-4' }
            ]
          }]}
          state={metrics}
          onChange={onChangeMetric}
        />
        <Title level={4} style={{marginTop: 20}}>
          Group by
        </Title>
        <GroupByFields
          stateKey='group_by'
          state={groupBy}
          onChange={onChangeGroupBy}
          dataSource={[
            {
              title: 'All',
              value: 'all',
              key: '0-0',
              children: [
                {
                  title: 'Campaign',
                  value: 'campaign_name',
                  key: '0-0-0',
                },
                {
                  title: 'Line Item',
                  value: 'line_item_name',
                  key: '0-0-1',
                },
                {
                  title: 'Site',
                  value: 'site_name',
                  key: '0-0-2',
                },
                {
                  title: 'Format',
                  value: 'ad_format',
                  key: '0-0-3',
                },
                {
                  title: 'Device',
                  value: 'device',
                  key: '0-0-4',
                },
                {
                  title: 'Creative',
                  value: 'creative_name',
                  key: '0-0-5',
                }
              ],
            },
          ]}
        />
        <GroupByDate
          stateKey='date_group'
          state={groupBy}
          onChange={onChangeGroupBy}
        />
      </Fragment>
    )
  }
}

export default ImproveDigitalCampaignsSummary
