/** @jsx jsx */
import {jsx} from '@emotion/core'

import {Report} from './abstract'
import {Fragment} from 'react'
import {Typography} from 'antd'
import Filter from 'src/filters/filter'
import moment from 'moment'
import {
  commonValidateFiltersInputRules,
  dayOfWeekNormalizer,
  formatDateRange,
  isInskinAccount,
  isPublisherAccount,
  monthNormalizer,
} from 'src/utils/helper'
import FilterDate from '../filters/filter-date'
import MetricCustom from '../metrics/metric-custom'
import GroupByFields from '../group-by/group-by-fields'
import GroupByDate from '../group-by/group-by-date'
import SwitchGroup from '../components/switch-group'
import * as genericClient from '../utils/generic-api'

const {Title} = Typography

class EventBreakdownReport extends Report {
  constructor(id?: string, name?: string, url?: string, options?: any) {
    const merged_options = {
      filterDefaults: {
        campaign: undefined,
        date_range: 'date_to_date',
        date_from: moment()
          .startOf('month'),
        date_to: moment()
          .endOf('day'),
        event_types: ['silent', 'non_silent']
      },
      metricDefaults: { general: [
          'displayed_impressions',
          'clicks',
          'ctr'
        ]},
      // disableDateRangeOptions: ['last_2_months', 'this_month', 'this_quarter', 'this_year', 'last_week', 'last_month', 'last_year', 'last_n_days'],
      groupByDefaults: {
        group_by: ['campaign_name'],
        date_group: 'lifetime'
      }
    };

    if (typeof options === 'object') {
      Object.assign(merged_options, options);
    }

    super(
      id || 'reports:event-breakdown',
      name || 'Event Breakdown (Beta)',
      url || 'event-breakdown',
      merged_options
    )
  }

  static get className() {
    return 'EventBreakdownReport';
  }

  getAvailableUsersCampaignsToShowInReport(user?: any) {
    if (isInskinAccount(user)) {
      return null;
    }
    if (isPublisherAccount(user)) {
      return user.campaigns_raw.filter((campaign: any) => campaign.isCreator);
    }

    return [];
  }

  validateFiltersInputRules({filters}: any) {
    if (!filters.campaign) {
      return [
        'Please select a campaign from the list to continue',
      ]
    }

    return commonValidateFiltersInputRules({ filters });
  }


  userHasCampaignsToShowInReport(user?: any) {
    const campaigns = this.getAvailableUsersCampaignsToShowInReport(user);

    return isInskinAccount(user) || (campaigns && !!campaigns.length);
  }

  prepareBody({filters, metrics, groupBy, user}: any) {
    const body: any = {
      type: this.id?.split(':')[1], // TODO: Make it better through currentReportType
      filters: [],
      fields: [
        ...metrics.general,
        ...groupBy.group_by
      ],
      dateRange: formatDateRange(filters.date_range, filters),
      dateGroup: groupBy.date_group
    };

    if (filters.campaign) {
      body.filters.push({
        op: '=',
        field: 'campaign_id',
        value: filters.campaign,
      })
    }

    if (filters.event_types && filters.event_types.length) {
      body.fields.push('_interactions_');

      if (filters.event_types.length === 1) {
        body.filters.push({
          field: 'is_silent',
          op: '=',
          value: filters.event_types[0] === 'silent'
        })
      }
    }

    if (!isInskinAccount(user) && this.userHasCampaignsToShowInReport(user)) {
      body.filters.push({
        op: 'in',
        field: 'campaign_id',
        value: this.getAvailableUsersCampaignsToShowInReport(user).map((campaign: any) => campaign.id),
        extra: true
      });
    }

    return body;
  }

  async fetchFiltersData(signal: any) {
    let filtersData = {};

    try {
      filtersData = {
        campaigns: (await genericClient.search('campaigns?$or[0][isISAP]=true&$or[1][isImproveDigital]=true&$order=-endDate', '', signal)).data,
      };
    } catch (e: any) {
      if (signal.aborted) {
        console.log('[fetchFiltersData] ABORTED!')
      }
    }

    return filtersData;
  }

  renderSidebar({
                  user,
                  filters,
                  filtersData,
                  metrics,
                  groupBy,
                  onChangeFilter,
                  onChangeMetric,
                  onChangeGroupBy
                }: any) {

    return (
      <Fragment>
        <Title level={4} style={{marginTop: 20}}>
          General
        </Title>
        <FilterDate
          options={this.options}
          state={filters}
          onChange={onChangeFilter}
        />
        <Filter
          label='Campaign'
          // url='campaigns?isCreator=false&$order=-id'
          stateKey='campaign'
          state={filters}
          initialLoading={this.checkIfReportHasDataToFetch(user) ? !filtersData.campaigns : undefined}
          dataSource={this.checkIfReportHasDataToFetch(user) ? filtersData.campaigns : this.getAvailableUsersCampaignsToShowInReport(user)}
          onChange={onChangeFilter}
          mode={'default'}
        />
        <SwitchGroup
          label={'Event Types:'}
          fieldName={'event_types'}
          dataSource={[
            { type: 'silent', label: 'Silent:' },
            { type: 'non_silent', label: 'Non-Silent:' }
          ]}
          onChange={onChangeFilter}
          defaultValue={filters.event_types}
        />
        <Title level={4} style={{marginTop: 20}}>
          Metrics
        </Title>
        <MetricCustom
          label={'General:'}
          stateKey='general'
          dataSource={[{
            title: 'All',
            value: 'general',
            key: '0-0',
            children: [
              { title: 'Displayed Ad Impressions',
                value: 'displayed_impressions',
                key: '0-0-1' },
              { title: 'Clicks',
                value: 'clicks',
                key: '0-0-2' },
              { title: 'CTR %',
                value: 'ctr',
                key: '0-0-3' }
            ]
          }]}
          state={metrics}
          onChange={onChangeMetric}
        />
        <Title level={4} style={{marginTop: 20}}>
          Group by
        </Title>
        <GroupByFields
          stateKey='group_by'
          state={groupBy}
          onChange={onChangeGroupBy}
          dataSource={[{
            title: 'All',
            value: 'all',
            key: '0-0',
            children: [
              { title: 'Campaign',
                value: 'campaign_name',
                key: '0-0-0' },
              { title: 'Creative',
                value: 'creative_name',
                key: '0-0-2' },
              { title: 'Section',
                value: 'section_name',
                key: '0-0-3' },
              { title: 'Format',
                value: 'ad_format',
                key: '0-0-4' },
              { title: 'Device',
                value: 'device',
                key: '0-0-5' }
            ]
          }]}
        />
        <GroupByDate
          stateKey='date_group'
          state={groupBy}
          onChange={onChangeGroupBy}
        />
      </Fragment>
    )
  }

  get orderColumns(): any[] {
    return [
      'day_hour_utc',
      'date_utc',
      'day',
      { name: 'day_of_week',
        normalizer: dayOfWeekNormalizer
      },
      'week',
      { name: 'month',
        normalizer: monthNormalizer
      },
      'year',
      'campaign_name',
      'creative_name',
      'section_name',
      'ad_format',
      'device'
    ]
  }
}

export default EventBreakdownReport
