/** @jsx jsx */
import {useAuth} from '../context/auth-context'
import {API_URL} from '../utils/api-client'

function GhostLogout(props: any) {
  const {logout} = useAuth()

  const nextLogoutUrl = getNextLogoutUrl('auth,tools,reports');

  if (nextLogoutUrl) {
    logout(`${API_URL}/auth/logout?redirect=${encodeURIComponent(nextLogoutUrl)}`);
  } else {
    logout();
  }

  return null
}

export default GhostLogout;

/**
 * This function returns the URL that you need to redirect to once the logout
 * operation is complete.
 */
function getNextLogoutUrl(APPS = '') {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  let nextLogoutUrl = '';

  let redirect = params.get('redirect');
  if (redirect) {
    nextLogoutUrl = `${redirect.startsWith('http') ? '' : 'http://'}${redirect}`;
  }

  const re = /^([a-z0-9]+)((\.dev|\.staging)?\.(inskinmedia\.com|arcplatform\.net))$/;
  const matches = url.hostname.match(re);
  if (matches) {
    const appsParam = params.get('apps') === null ? APPS : params.get('apps');
    const apps = appsParam ? appsParam.split(',') : [];

    const app = matches[1];
    const i = apps.indexOf(app);
    if (i > -1) {
      apps.splice(i, 1);
    }

    if (apps.length) {
      const domain = matches[2];
      const nextApp = apps.shift();
      nextLogoutUrl = `${url.protocol}//${nextApp}${domain}/logout?apps=${apps.join(',')}${redirect ? `&redirect=${encodeURIComponent(redirect)}` : ''}`;
    }
  }

  return nextLogoutUrl;
}
