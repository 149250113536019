/** @jsx jsx */
import {jsx} from '@emotion/core'

import FilterDate from 'src/filters/filter-date'
import {Fragment} from 'react'
import Filter from 'src/filters/filter'
import {Typography, Collapse, Empty} from 'antd'
import MetricViewability from 'src/metrics/metric-viewability'
import GroupByFields from 'src/group-by/group-by-fields'
import GroupByDate from 'src/group-by/group-by-date'
import MetricCustom from '../metrics/metric-custom'
import {
  commonValidateFiltersInputRules,
  dayOfWeekNormalizer,
  getSiteTitle,
  isInskinAccount,
  monthNormalizer,
} from '../utils/helper'
import PageHeader from '../components/page-header'
import ReactSpeedometer from 'react-d3-speedometer'
import NoDataImage from '../assets/no_creatives.svg'
import ExportButton from '../components/export-button'
import AgencyCampaignsSummaryReport from './agency-campaigns-summary'
import * as genericClient from '../utils/generic-api'

const {Title} = Typography
const {Panel} = Collapse

class CreatorCampaignsSummary extends AgencyCampaignsSummaryReport {
  constructor(id?: string, name?: string, url?: string, options?: any) {
    const merged_options = {
      disableDateRangeOptions: [],
      filterDefaults: {
        campaign: undefined,
        date_range: 'last_2_months',
      },
      metricDefaults: {
        general: [
          'displayed_impressions',
          'clicks',
          'ctr'
        ],
        viewability: [
          'in_view_pct',
          'in_view_time',
          'in_view_gt_60_pct',
          'exposure_time',
        ],
        financial: [],
        creative: [],
        attention: []
      },
      groupByDefaults: {
        group_by: ['campaign_name', 'creative_name'],
        date_group: 'lifetime',
      },
    };

    if (typeof options === 'object') {
      Object.assign(merged_options, options);
    }

    super(
      id || 'reports:creator-campaigns-summary',
      name || 'Arc Campaigns Summary Report',
      url || 'creator-campaigns-summary',
      merged_options
    )
  }

  static get className() {
    return 'CreatorCampaignsSummary';
  }

  getAvailableUsersCampaignsToShowInReport(user?: any) {
    if (isInskinAccount(user)) {
      return null;
    }
    return user.campaigns_raw.filter((campaign: any) => campaign.isCreator);
  }

  userHasCampaignsToShowInReport(user?: any) {
    const campaigns = this.getAvailableUsersCampaignsToShowInReport(user);

    return isInskinAccount(user) || (campaigns && !!campaigns.length);
  }

  prepareBody({filters, metrics, groupBy, user}: any) {
    const body = super.prepareBody({filters, metrics, groupBy, user});

    if (filters.campaign) {
      body.filters.push({
        op: '=',
        field: 'campaign_id',
        value: filters.campaign
      })
    }

    return body;
  }

  checkIfReportHasInitialState(user?: any) {
    return !isInskinAccount(user);
  }

  validateFiltersInputRules({filters}: any) {
    if (!filters.campaign) {
      return [
        'Please select a campaign from the list to continue',
      ]
    }

    return commonValidateFiltersInputRules({ filters });
  }

  get initialReportType(): string|undefined {
    return 'campaigns-summary-dashboard';
  }

  prepareBodyForInitialFetch({user, filters, groupBy}: any) {
    return {
      type: this.initialReportType,
      filters: [{
        op: 'in',
        field: 'campaign_id',
        value: this.getAvailableUsersCampaignsToShowInReport(user).map((campaign: any) => campaign.id),
        extra: true
      }],
      fields: [
        'campaign_id',
        'campaign_name',
        'ctr',
        'in_view_pct'
      ],
      dateGroup: 'lifetime',
    }
  }

  checkIfReportHasDataToFetch(user: any) {
    return isInskinAccount(user);
  }

  async fetchFiltersData(signal: any): Promise<any> {
    let filtersData = {};

    try {
      filtersData = {
        campaigns: (await genericClient.search('campaigns?isCreator=true&$order=-endDate', '', signal)).data,
      };
    } catch (e: any) {
      if (signal.aborted) {
        console.log('[fetchFiltersData] ABORTED!')
      }
    }

    return filtersData;
  }

  renderSidebar({
                  user,
                  filters,
                  filtersData,
                  metrics,
                  groupBy,
                  onChangeFilter,
                  onChangeMetric,
                  onChangeGroupBy,
                }: any) {

    return (
      <Fragment>
        <Title level={4} style={{marginTop: 20}}>
          General
        </Title>
        <FilterDate
          options={this.options}
          state={filters}
          onChange={onChangeFilter}
        />
        <Filter
          label='Campaign'
          // url='campaigns?isCreator=true&$order=-id'
          stateKey='campaign'
          state={filters}
          initialLoading={this.checkIfReportHasDataToFetch(user) ? !filtersData.campaigns : undefined}
          dataSource={this.checkIfReportHasDataToFetch(user) ? filtersData.campaigns : this.getAvailableUsersCampaignsToShowInReport(user)}
          onChange={onChangeFilter}
          mode={'default'}
        />
        <Title level={4} style={{marginTop: 20}}>
          Metrics
        </Title>
        <MetricCustom
          label={'General:'}
          stateKey='general'
          dataSource={[{
            title: 'All',
            value: 'general',
            key: '0-0',
            children: [
              { title: 'Displayed Ad Impressions',
                value: 'displayed_impressions',
                key: '0-0-1' },
              { title: 'Invalid Impressions',
                value: 'invalid_impressions',
                key: '0-0-2' },
              { title: 'Clicks',
                value: 'clicks',
                key: '0-0-3' },
              { title: 'CTR %',
                value: 'ctr',
                key: '0-0-4' }
            ]
          }]}
          state={metrics}
          onChange={onChangeMetric}
        />
        <MetricViewability
          stateKey='viewability'
          state={metrics}
          onChange={onChangeMetric}
        />
        <MetricCustom
          label={'Attention:'}
          stateKey='attention'
          dataSource={[{
            title: 'All',
            value: 'general',
            key: '0-0',
            children: [
              { title: '% of Total Impressions Looked At',
                value: 'pct_total_impressions_looked_at',
                key: '0-0-1' },
              { title: 'Avg. Visual Engagement Time',
                value: 'avg_visual_engagement_time',
                key: '0-0-2' },
              { title: 'Attention per 1000 Impressions',
                value: 'attention_per_1000_impressions',
                key: '0-0-3' },
              { title: 'Total Attention',
                value: 'total_attention_hours',
                key: '0-0-4' }
            ]
          }]}
          state={metrics}
          onChange={onChangeMetric}
        />
        <Title level={4} style={{marginTop: 20}}>
          Group by
        </Title>
        <GroupByFields
          stateKey='group_by'
          state={groupBy}
          onChange={onChangeGroupBy}
          dataSource={[
            {
              title: 'All',
              value: 'all',
              key: '0-0',
              children: [
                {
                  title: 'Campaign',
                  value: 'campaign_name',
                  key: '0-0-0',
                },
                {
                  title: 'Site',
                  value: 'site_name',
                  key: '0-0-1',
                },
                {
                  title: 'Format',
                  value: 'ad_format',
                  key: '0-0-2',
                },
                {
                  title: 'Device',
                  value: 'device',
                  key: '0-0-3',
                },
                {
                  title: 'Creative',
                  value: 'creative_name',
                  key: '0-0-4',
                }
              ],
            },
          ]}
        />
        <GroupByDate
          stateKey='date_group'
          state={groupBy}
          onChange={onChangeGroupBy}
        />
      </Fragment>
    )
  }

  renderInitialResults({initialResults, user}: any) {
    if (!initialResults) {
      return <div />;
    }

    return (
      <div style={{margin: 20, width: '100%'}}>
        <PageHeader title={`Welcome to ${getSiteTitle()}`} showBackButton={false} />
        {initialResults.result.rows.length ? (
          <div>
            {initialResults.result.rows.length ? (
              <Collapse defaultActiveKey={['0']} style={{marginBottom: 20}}>
                {initialResults.result.rows
                  .map((item: any, index: number) => (
                    <Panel header={item.campaign_name} key={String(index)} extra={
                      <ExportButton
                        user={user}
                        reportFilter={{ campaign: item.campaign_id }}
                        reportInstance={this} />
                    }>
                      <div
                        style={{
                          display: 'flex',
                          flex: 1,
                          width: '100%',
                        }}
                      >
                        <div
                          style={{
                            flexGrow: 1,
                            flexBasis: 0,
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flex: 1,
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginBottom: 20,
                            }}
                          >
                            <Title level={3}>Viewability</Title>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flex: 1,
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <ReactSpeedometer
                              minValue={0}
                              maxValue={100}
                              value={
                                item.viewability_pct <= 100 ? item.viewability_pct : 100
                              }
                              segmentColors={[
                                '#364756',
                                '#4c6071',
                                '#647a8d',
                                '#798fa3',
                                '#97a9ba',
                              ]}
                              needleColor="#333333"
                              // eslint-disable-next-line no-template-curly-in-string
                              currentValueText="${value}%"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            flexGrow: 1,
                            flexBasis: 0,
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flex: 1,
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginBottom: 20,
                            }}
                          >
                            <Title level={3}>CTR</Title>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flex: 1,
                              justifyContent: 'center',
                              alignItems: 'flex-start',
                            }}
                          >
                            <div style={{height: 300}}>
                              <div
                                style={{
                                  fontSize: 60,
                                  fontWeight: 'bold',
                                  color: '#c4c4c4',
                                  border: '2px solid #c4c4c4',
                                  padding: '30px 40px',
                                  minWidth: 300,
                                  textAlign: 'center'
                                }}
                              >
                                {`${item.ctr}%`}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Panel>
                  ))}
              </Collapse>
            ) : null}
          </div>
        ) : <div style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}>
          <Empty
            style={{ margin: 'auto' }}
            image={NoDataImage}
            imageStyle={{ height: 250 }}
            description={<Fragment>There is no data to show!</Fragment>}
          />
        </div>}
      </div>
    )
  }

  get orderColumns(): any[] {
    return [
      'day_hour_utc',
      'date_utc',
      'day',
      { name: 'day_of_week',
        normalizer: dayOfWeekNormalizer
      },
      'week',
      { name: 'month',
        normalizer: monthNormalizer
      },
      'year',
      'campaign_name',
      'ad_format',
      'device',
      'creative_name'
    ]
  }
}

export default CreatorCampaignsSummary
