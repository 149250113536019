import React, {Fragment} from 'react'
import {useUser} from './context/user-context'
import {Global, css} from '@emotion/core'
import * as colors from './styles/colors'

import Route from './Route'
import {Router, useLocation} from '@reach/router'
import GhostRedirect from './screens/ghostRedirect'
import GhostLogout from './screens/ghostLogout'
import queryString from 'query-string'

import './styles/themes/inskin-reports-theme.less'
import {setToken} from './utils/auth-client'

const loadAuthenticatedApp = () => import('./authenticated-app')
const AuthenticatedApp = React.lazy(loadAuthenticatedApp)
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'))

function Routes() {
  return (
    <Router>
      <Route component={GhostRedirect} path="auth/google" />
      <Route component={GhostRedirect} path="reset_password" />
      <Route component={GhostLogout} path="logout" />
    </Router>
  )
}

function App() {
  const props = useLocation();
  const user = useUser()

  const parsedParameters: any = queryString.parse(props.search);

  if (parsedParameters.jwt) {
    setToken(parsedParameters.jwt.toString());

    const search = Object.entries(parsedParameters).reduce((acc, [key, value]) => {
      if (key !== 'jwt') {
        acc = `${acc}${acc.length ? '&' : ''}${key}=${value}`
      }
      return acc;
    }, '');

    window.location.href = `${window.location.origin}${window.location.pathname}?${search}`

    return <div/>;
  }

  return (
    <Fragment>
      <Global
        styles={css`
          html,
          body {
            height: 100%;
          }
          #root {
            display: flex;
            flex: 1;
            width: 100%;
            height: auto;
            min-height: 100vh
          }
          .ant-menu {
            color: white;
            position: relative;
            lineheight: 64px;
            margin: 0;
            background-color: ${colors.dark}!important;
          }
          .ant-menu-item-selected {
            border-color: white !important;
          }
          .ant-menu-item > a {
            font-weight: bold;
            color: #a8a8a8 !important;
            &:link {
              color: white !important;
            }
          }
          .ant-menu-item-selected > a {
            color: white !important;
          }
          .reportResults {
            margin: 20px;
            width: 100%;
            overflow-y: auto
          }
          .ant-layout-header {
            padding: 0 !important;
          }
          .box-shadow-10 {
            -webkit-box-shadow: #d0e9ff 0 0 10px 10px;
            box-shadow: #d0e9ff 0 0 10px 10px;
          }
          .color {
            color: #1890ff;
          }
        `}
      />
      <Routes />
      <React.Suspense fallback={<div>Loading...</div>}>
        {(user && !parsedParameters.redirect) ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </React.Suspense>
    </Fragment>
  )
}

export default App
