/** @jsx jsx */
import {jsx} from '@emotion/core'
import {FunctionComponent} from 'react'
import {RouteProps} from './types/types'

// TODO: Move this to components and lower letter

const Route: FunctionComponent<RouteProps> = ({
  component: Component,
  ...rest
}) => <Component {...rest} />

export default Route
