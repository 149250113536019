/** @jsx jsx */
import {jsx, css} from '@emotion/core'

import {Table, Input, Empty} from 'antd'
import {Fragment} from 'react'
import NoDataImage from '../assets/no_creatives.svg'

const {Search} = Input

interface ClassicTableProps {
  columns: any[]
  dataSource: any
  footerSource?: any
  actions?: any[]
  showSearch?: boolean
  selectable?: boolean
  onSearch: (arg0: any) => void
  onRowEnabled: (arg0: any) => boolean
  noDataMessage?: any
  tableShouldNotBeEmpty?: boolean
  tableMaxHeightCSS?: string,
  pagination?: any
}

function ClassicTable(props: ClassicTableProps) {
  const {
    columns,
    dataSource,
    footerSource,
    onSearch,
    onRowEnabled,
    actions = [],
    showSearch = true,
    selectable = true,
    noDataMessage = <Fragment>No data found!<br />Please, try to adjust filters.</Fragment>,
    tableShouldNotBeEmpty = false,
    tableMaxHeightCSS,
    pagination = false
  } = props;
  let defaultTableMaxHeightCSS = 'calc( 100vh - 245px )';
  let tableExtras: any = {};

  if (!tableShouldNotBeEmpty) {
    return (<div style={{
      height: '100%',
      display: 'flex',
      justifyContent: 'center'
    }}>
      <Empty
        style={{ margin: 'auto' }}
        image={NoDataImage}
        imageStyle={{ height: 250 }}
        description={noDataMessage}
      />
    </div>);
  }

  if (footerSource) {
    defaultTableMaxHeightCSS = 'calc( 100vh - 343px )'
    tableExtras.footer = () => (
      <table>
        <thead className="ant-table-thead">
        <tr>
          {
            columns.map(column =>
              (<th key={column.dataIndex} style={{ width: column.width }}>
                {footerSource[column.dataIndex]}
              </th>))
          }
        </tr>
        </thead>
      </table>
    );
  }

  return (
    <div>
      {showSearch || actions.length > 0 ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            marginBottom: 15,
            background: 'white',
            padding: 15,
            borderRadius: 5,
            border: '1px solid #d5d5d5',
          }}
        >
          {actions.length > 0 && (
            <div
              css={css`
                float: left;
                display: flex;
                flex: 1;
                .ant-btn {
                  margin-right: 15px;
                }
              `}
            >
              {actions?.map((action, index) => (
                <Fragment key={index}>{action}</Fragment>
              ))}
            </div>
          )}
          {showSearch && (
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-end',
              }}
            >
              <Search
                allowClear={true}
                size="large"
                placeholder="Search..."
                onSearch={value => onSearch(value)}
                onChange={e => onSearch(e.target.value)}
                style={{maxWidth: 400}}
              />
            </div>
          )}
        </div>
      ) : ''}
      {(!dataSource || dataSource.length === 0) ? '' : (
        <Table
          data-cy="report_table"
          pagination={pagination}
          columns={columns}
          dataSource={dataSource}
          rowKey={(_, index) => String(index)}
          css={css`
            .ant-table-header {
              border-radius: 5px 5px 0 0;
              border: 1px solid #d5d5d5;
              border-bottom: 0;
            }
            .ant-table-header .ant-table-thead > tr.first-child > tg.first-child {
              border-bottom-left-radius: 0;
            }
            .ant-table-body {
              ${footerSource ? '' : 'border-radius: 0 0 5px 5px;' }
              border: 1px solid #d5d5d5;
              border-top: 0;
              background: #ffffff;
              user-select: ${selectable ? 'auto' : 'none'};
              overflow: overlay;
              max-height: ${tableMaxHeightCSS || defaultTableMaxHeightCSS};
            }
            .ant-table-footer th {
              background: #fafafa;
              font-weight: bold;
            }
            .ant-table-footer * {
              border: 0;
            }
            .ant-table-footer {
              padding: 0px;
              border: 0;
            }
            .ant-table-footer {
              border-radius: 0 0 5px 5px;
              border: 1px solid #d5d5d5;
            }
            .ant-table-footer > table > .ant-table-thead > tr:first-child > th:first-child {
              border-bottom-left-radius: 5px;
            }
            .ant-table-footer > table > .ant-table-thead > tr:first-child > th:last-child {
              border-bottom-right-radius: 5px;
            }
            .disabled-row {
              opacity: 0.25;
            }
          `}
          rowClassName={(record: any) =>
            onRowEnabled(record) ? 'enabled-row' : 'disabled-row'
          }
          useFixedHeader
          {...tableExtras}
        />
      )}
    </div>
  )
}

export default ClassicTable
