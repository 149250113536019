/** @jsx jsx */
import {jsx} from '@emotion/core'

import {Report} from './abstract'
import FilterDate from 'src/filters/filter-date'
import {Fragment} from 'react'
import Filter from 'src/filters/filter'
import FilterCampaignStatus from 'src/filters/filter-campaign-status'
import {Typography} from 'antd'
import MetricGeneral from 'src/metrics/metric-general'
import MetricViewability from 'src/metrics/metric-viewability'
import MetricFinancial from 'src/metrics/metric-financial'
import MetricCreative from 'src/metrics/metric-creative'
import GroupByFields from 'src/group-by/group-by-fields'
import GroupByDate from 'src/group-by/group-by-date'
import {
  commonValidateFiltersInputRules,
  dayOfWeekNormalizer,
  formatDateRange,
  getDefaultCampaignsFilterAsyncOnFilter,
  monthNormalizer,
} from 'src/utils/helper'
import MetricCustom from '../metrics/metric-custom'
import * as genericClient from '../utils/generic-api'

const {Title} = Typography

class CampaignsSummaryReport extends Report {
  constructor(id?: string, name?: string, url?: string, options?: any) {
    const merged_options = {
      disableDateRangeOptions: ['today'],
      filterDefaults: {
        campaigns: [],
        agencies: [],
        advertisers: [],
        offices: [],
        date_range: 'last_2_months',
      },
      metricDefaults: {
        general: [
          'booked_impressions',
          'displayed_impressions',
          'clicks',
          'ctr',
          'unique_users',
        ],
        viewability: [
          'in_view_pct',
          'in_view_time',
          'in_view_gt_60_pct',
          'exposure_time',
        ],
        financial: [],
        creative: [],
        attention: [],
      },
      groupByDefaults: {
        group_by: ['campaign_name', 'line_item_name'],
        date_group: 'lifetime',
      },
    }

    if (typeof options === 'object') {
      Object.assign(merged_options, options)
    }

    super(
      id || 'reports:campaigns-summary',
      name || 'Campaigns Summary Report',
      url || 'campaigns-summary',
      merged_options,
    )
  }

  static get className() {
    return 'CampaignsSummaryReport'
  }

  get orderColumns(): any[] {
    return [
      'day_hour_utc',
      'date_utc',
      'day',
      {
        name: 'day_of_week',
        normalizer: dayOfWeekNormalizer,
      },
      'week',
      {
        name: 'month',
        normalizer: monthNormalizer,
      },
      'year',
      'agency_name',
      'campaign_name',
      'line_item_name',
      'site_name',
      'ad_format',
      'device',
      'creative_name',
    ]
  }

  validateFiltersInputRules({filters}: any) {
    if (!filters.campaigns && !filters.agencies && !filters.advertisers) {
      return [
        'Please select a campaign, agency or advertiser from the list to continue',
      ]
    }
    return commonValidateFiltersInputRules({filters})
  }

  prepareBody({filters, metrics, groupBy}: any) {
    let dateRange = formatDateRange(filters.date_range, filters)

    const customFilters = []

    if (filters.campaigns && filters.campaigns.length) {
      customFilters.push({
        op:
          Array.isArray(filters.campaigns) && filters.campaigns.length > 1
            ? 'in'
            : '=',
        field: 'campaign_id',
        value:
          Array.isArray(filters.campaigns) && filters.campaigns.length > 1
            ? filters.campaigns
            : filters.campaigns[0],
        extra: false,
      })
    }

    if (filters.campaign_status && filters.campaign_status.length) {
      customFilters.push({
        op:
          Array.isArray(filters.campaign_status) &&
          filters.campaign_status.length > 1
            ? 'in'
            : '=',
        field: 'campaign_status',
        value:
          Array.isArray(filters.campaign_status) &&
          filters.campaign_status.length > 1
            ? filters.campaign_status
            : filters.campaign_status[0],
      })
    }

    if (filters.agencies && filters.agencies.length) {
      customFilters.push({
        op:
          Array.isArray(filters.agencies) && filters.agencies.length > 1
            ? 'in'
            : '=',
        field: 'agency_id',
        value:
          Array.isArray(filters.agencies) && filters.agencies.length > 1
            ? filters.agencies
            : filters.agencies[0],
      })
    }

    if (filters.advertisers && filters.advertisers.length) {
      customFilters.push({
        op:
          Array.isArray(filters.advertisers) && filters.advertisers.length > 1
            ? 'in'
            : '=',
        field: 'advertiser_id',
        value:
          Array.isArray(filters.advertisers) && filters.advertisers.length > 1
            ? filters.advertisers
            : filters.advertisers[0],
      })
    }

    if (filters.offices && filters.offices.length) {
      customFilters.push({
        op:
          Array.isArray(filters.offices) && filters.offices.length > 1
            ? 'in'
            : '=',
        field: 'inskin_office',
        value:
          Array.isArray(filters.offices) && filters.offices.length > 1
            ? filters.offices
            : filters.offices[0],
      })
    }

    const data = {
      type: this.id?.split(':')[1], // TODO: Make it better through currentReportType
      dateRange,
      filters: customFilters,
      fields: [
        ...metrics.general,
        ...metrics.viewability,
        ...metrics.creative,
        ...metrics.financial,
        ...metrics.attention,
        ...groupBy.group_by,
      ],
      dateGroup: groupBy.date_group,
    }

    return data
  }

  async fetchFiltersData(signal: any): Promise<any> {
    let filtersData = {};

    try {
      filtersData = {
        campaigns: (await genericClient.search('campaigns?isISAP=true&$order=-endDate', '', signal)).data,
        agencies: (await genericClient.search('accounts?type=agency&$order=name', '', signal)).data,
        advertisers: (await genericClient.search('accounts?type=advertiser&$order=name', '', signal)).data,
        offices: (await genericClient.search('offices?$order=name', '', signal)).data,
      };
    } catch (e: any) {
      if (signal.aborted) {
        console.log('[fetchFiltersData] ABORTED!')
      }
    }

    return filtersData;
  }

  renderSidebar({
                  user,
                  filters,
                  filtersData,
                  metrics,
                  groupBy,
                  onChangeFilter,
                  onChangeMetric,
                  onChangeGroupBy,
                }: any) {
    return (
      <Fragment>
        <Title level={4} style={{marginTop: 20}}>
          General
        </Title>
        <FilterDate
          options={this.options}
          state={filters}
          onChange={onChangeFilter}
          disabledDaysFromNow={1}
        />
        <Filter
          label={'Campaigns'}
          // url='campaigns?isCreator=false&$order=-id'
          stateKey="campaigns"
          state={filters}
          initialLoading={!filtersData.campaigns}
          dataSource={filtersData.campaigns}
          onChange={onChangeFilter}
          onFilter={getDefaultCampaignsFilterAsyncOnFilter(filters)}
        />
        <Filter
          label="Agencies"
          // url="accounts?type=agency"
          stateKey="agencies"
          state={filters}
          initialLoading={!filtersData.agencies}
          dataSource={filtersData.agencies}
          onChange={onChangeFilter}
        />
        <Filter
          label="Advertisers"
          // url="accounts?type=advertiser"
          stateKey="advertisers"
          state={filters}
          initialLoading={!filtersData.advertisers}
          dataSource={filtersData.advertisers}
          onChange={onChangeFilter}
        />
        <Filter
          label="Offices"
          // url="offices"
          stateKey="offices"
          state={filters}
          initialLoading={!filtersData.offices}
          dataSource={filtersData.offices}
          onChange={onChangeFilter}
        />
        <FilterCampaignStatus state={filters} onChange={onChangeFilter} />
        <Title level={4} style={{marginTop: 20}}>
          Metrics
        </Title>
        <MetricGeneral
          stateKey="general"
          state={metrics}
          onChange={onChangeMetric}
        />
        <MetricViewability
          stateKey="viewability"
          state={metrics}
          onChange={onChangeMetric}
        />
        <MetricFinancial
          stateKey="financial"
          state={metrics}
          onChange={onChangeMetric}
        />
        <MetricCreative
          stateKey="creative"
          state={metrics}
          onChange={onChangeMetric}
        />
        <MetricCustom
          label={'Attention:'}
          stateKey="attention"
          dataSource={[{
            title: 'All',
            value: 'general',
            key: '0-0',
            children: [
              {
                title: '% of Total Impressions Looked At',
                value: 'pct_total_impressions_looked_at',
                key: '0-0-1',
              },
              {
                title: 'Avg. Visual Engagement Time',
                value: 'avg_visual_engagement_time',
                key: '0-0-2',
              },
              {
                title: 'Attention per 1000 Impressions',
                value: 'attention_per_1000_impressions',
                key: '0-0-3',
              },
              {
                title: 'Total Attention',
                value: 'total_attention_hours',
                key: '0-0-4',
              },
            ],
          }]}
          state={metrics}
          onChange={onChangeMetric}
        />
        <Title level={4} style={{marginTop: 20}}>
          Group by
        </Title>
        <GroupByFields
          stateKey="group_by"
          state={groupBy}
          onChange={onChangeGroupBy}
          dataSource={[
            {
              title: 'All',
              value: 'all',
              key: '0-0',
              children: [
                {
                  title: 'Agency',
                  value: 'agency_name',
                  key: '0-0-1',
                },
                {
                  title: 'Campaign',
                  value: 'campaign_name',
                  key: '0-0-2',
                },
                {
                  title: 'Line Item',
                  value: 'line_item_name',
                  key: '0-0-3',
                },
                {
                  title: 'Creative',
                  value: 'creative_name',
                  key: '0-0-4',
                },
                {
                  title: 'Site',
                  value: 'site_name',
                  key: '0-0-5',
                },
                {
                  title: 'Format',
                  value: 'ad_format',
                  key: '0-0-6',
                },
                {
                  title: 'Device',
                  value: 'device',
                  key: '0-0-7',
                },
              ],
            },
          ]}
        />
        <GroupByDate
          stateKey="date_group"
          state={groupBy}
          onChange={onChangeGroupBy}
        />
      </Fragment>
    )
  }
}

export default CampaignsSummaryReport
