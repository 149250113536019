import {API_URL, client} from './api-client'
import {UserResponse, User} from '../types/types'

const localStorageKey = '__inskin_reports_token__'
const localStorageSwapKey = '__inskin_reports_swap_token__'

function handleUserResponse(response: UserResponse) {
  if (!response) {
    return
  }

  const {user, jwt, redirect} = response;

  if (jwt) {
    window.localStorage.setItem(localStorageKey, jwt);
  }
  if (redirect) {
    window.location.href = redirect;

    return;
  }

  return user;
}

function getUser(): Promise<User | null> {
  const token = getToken()
  if (!token) {
    return Promise.resolve(null)
  }

  // campaigns,account.campaigns,account.subAccounts.campaigns
  return client('users/0?$include=account,campaigns,account.subAccounts,account.campaigns,account.subAccounts.campaigns').catch(
    error => {
      logout()
      return Promise.reject(error)
    },
  )
}

function login({email, password} = {email: '', password: ''}) {
  return client('auth/login', {body: {email, password}}, false).then(
    handleUserResponse,
  )
}

function register({username, password} = {username: '', password: ''}) {
  return client('register', {body: {username, password}}, false).then(
    handleUserResponse,
  )
}

function logout() {
  window.localStorage.removeItem(localStorageKey)
  window.localStorage.removeItem(localStorageSwapKey)
  return client('auth/logout', undefined, false);
}

function googleLogin() {
  window.location.href = `${API_URL}/auth/google`
}

function setToken(token: string) {
  return window.localStorage.setItem(localStorageKey, token)
}

function getToken() {
  return window.localStorage.getItem(localStorageKey)
}

function getSwapToken() {
  return window.localStorage.getItem(localStorageSwapKey)
}

function swapTokens(newToken: string) {
  const oldToken = getToken()
  window.localStorage.setItem(localStorageKey, newToken)
  window.localStorage.setItem(localStorageSwapKey, oldToken ? oldToken : '')
}

function unswapTokens() {
  const swapToken = getSwapToken()
  window.localStorage.setItem(localStorageKey, swapToken ? swapToken : '')
  window.localStorage.setItem(localStorageSwapKey, '')
}

function resetPassword({email, password} = {email: '', password: ''}) {
  return client(`auth/password-reset?email=${email}`, undefined, false)
}

function verifyGoogleToken(token: string) {
  return client('auth/login/token?$include=account', {body: {token}}, false).then(
    handleUserResponse,
  )
}

function createPassword({token, password}: any) {
  return client('auth/password-reset', {body: {token, password}}, false).then(
    handleUserResponse,
  )
}

export {
  login,
  register,
  logout,
  setToken,
  getToken,
  getUser,
  resetPassword,
  createPassword,
  googleLogin,
  verifyGoogleToken,
  swapTokens,
  unswapTokens,
  getSwapToken,
}
