/** @jsx jsx */
import {css, jsx} from '@emotion/core'
import React from 'react'
import Select, {components, defaultTheme, Props as SelectProps} from 'react-select'
import * as genericClient from '../utils/generic-api'
import {Form, Icon} from 'antd'
import {deepClone} from '../utils/helper'
import * as colors from '../styles/colors'

const {useEffect, useState} = React

const MAX_OPTIONS_COUNT = 500;
const objectsEqual = (o1: any, o2: any) =>
  Object.keys(o1).length === Object.keys(o2).length
  && Object.keys(o1).every(p => o1[p] === o2[p]);
const arraysEqual = (a1: any, a2: any) =>
  a1.length === a2.length
  && (
    a1.length === 0
    || a1.every((o: any, idx: number) => objectsEqual(o, a2[idx]))
  );
const setArray = (setFunc: any, currentValue: any, newValue: any) => {
  if (!arraysEqual(currentValue, newValue)) {
    setFunc(newValue);
  }
};

interface FilterProps {
  style?: any,
  dataSource?: any[]
  enableOptionAll?: boolean
  optionAllValue?: string
  url?: string
  stateKey: string
  state: any
  mode?: string,
  disabled?: boolean,
  onChange: (value: any, records: any) => void
  onFilter?: (value: any, index: number, array: any[]) => any
  initialLoading?: boolean
  getPopupContainer?: any
  label: string
}

function Filter(props: FilterProps & SelectProps) {
  const {
    style,
    dataSource,
    url = '',
    stateKey,
    state,
    mode = 'multiple',
    initialLoading = false,
    label,
    onChange,
    onFilter = () => true,
    enableOptionAll = false,
    optionAllValue,
    disabled = false,
  } = props

  if (enableOptionAll && !optionAllValue) {
    throw new Error('optionAllValue is not defined!')
  }

  const [selectableItems, setSelectableItems] = useState((dataSource || []).filter(onFilter))
  const [allSelectableItems, setAllSelectableItems] = useState(dataSource || [])
  const [isLoading, setIsLoading] = useState(initialLoading)

  useEffect(() => {
    if (isLoading !== initialLoading) {
      setIsLoading(initialLoading)
    }
  }, [initialLoading])

  useEffect(() => {
    if (dataSource && Array.isArray(dataSource)) {
      setArray(setAllSelectableItems, allSelectableItems, dataSource);
      setArray(setSelectableItems, selectableItems, dataSource.filter(onFilter));
    }
  }, [dataSource])

  useEffect(() => {
    if (allSelectableItems && allSelectableItems.length) {
      setArray(setSelectableItems, selectableItems, allSelectableItems.filter(onFilter))

      return;
    }

    if (url && (!state[stateKey] || (Array.isArray(state[stateKey]) && !state[stateKey].length))) {
      if (!isLoading) {
        setIsLoading(true);
      }
      genericClient.search(url).then(data => {
        setArray(setAllSelectableItems, allSelectableItems, allSelectableItems.filter(onFilter));
        setArray(setSelectableItems, selectableItems, data.data.filter(onFilter));
        setIsLoading(false);
      }).catch(((error) => {
        console.error(error)
        setArray(setSelectableItems, selectableItems, []);
        setIsLoading(false);
      }))
    }
  }, [state, allSelectableItems]);

  // TODO: move from using theme to styles
  const theme = deepClone(defaultTheme)

  Object.assign(theme.colors, {
    primary: colors.mainColor,
    primary75: '#4C9AFF',
    primary50: colors.highlightingColor,
    primary25: colors.highlightingColor,
    neutral20: '#d9d9d9',
    neutral30: colors.mainColor,
    neutral50: '#bfbfbf',
  })
  theme.controlHeight = 36
  const allOption = {value: '*', label: 'All'};

  let options: any = [
    ...(enableOptionAll ? [allOption] : []),
    ...(selectableItems || [])
      .map((opt: any) => ({value: opt.value || parseInt(opt.identifier, 10) || opt.id || opt.name, label: opt.label || opt.name})),
  ]

  if (options.length > (MAX_OPTIONS_COUNT + (enableOptionAll ? 1 : 0))) {
    const optionsCount = options.length;

    // options = options.slice(0, (MAX_OPTIONS_COUNT + (enableOptionAll ? 1 : 0)))
    options.push({
      value: '',
      label: `... There are ${optionsCount - MAX_OPTIONS_COUNT} ${mode === 'multiple' ? label.toLowerCase() : label.toLowerCase().replace(/y$/,'ie') + 's'} more. Please use search to find required ${label.toLowerCase()}.`,
      isDisabled: true,
      id: 'hidden'
    })
  }

  let value = null;

  if (selectableItems && selectableItems.length) {
    value = state[stateKey]
      ? (Array.isArray(state[stateKey]) ? state[stateKey] : [state[stateKey]])
        .map((selected: any) => selectableItems.filter((item: any) => item && (item.value || parseInt(item.identifier, 10) || item.id || item.name) === (parseInt(selected, 10) || selected))[0])
        .map((item: any) => item && ({value: parseInt(item.identifier, 10) || item.id || item.name, label: item.name}))
      : []
  }
  if (state[stateKey] === allOption.value) {
    value = allOption
  }

  return (
    <Form.Item
      label={label}
      style={{marginBottom: 0, ...(style || {})}}
      css={css`
        .ant-form-item-control {
          line-height: 26px;
          font-size: 16px;
        }
      `}
    >
      <Select
        placeholder={`Please select ${label.toLowerCase()}`}
        value={value}
        defaultValue={enableOptionAll ? allOption.value : undefined}
        isMulti={mode === 'multiple'}
        isClearable={false}
        isLoading={isLoading}
        isDisabled={(isLoading) || disabled}
        closeMenuOnSelect={mode !== 'multiple'}
        hideSelectedOptions={false}
        options={options}
        filterOption={({ label, value, data }, query) => !query || (data.id && data.id.includes('hidden')) || label.toLowerCase().includes(query.toLowerCase())}
        onChange={(value: any) => {
          let valueToSet

          if (mode === 'multiple') {
            valueToSet = value.map(({value}: any) => value)
          } else {
            valueToSet = value.value
          }
          // if (value.value === allOption.value || (Array.isArray(value) && value.filter(({value}: any) => value === allOption.value).length)) {
          //   valueToSet = undefined
          // }
          onChange(stateKey, valueToSet)
        }}
        components={{
          Input: (props) => (<components.Input {...props} data-cy={`${label.toLowerCase()}_select`} />),
          Option: (props) => (<div style={{position: 'relative'}}>
            <components.Option {...props} innerProps={ Object.assign({}, props.innerProps, { 'data-cy': `${label.toLowerCase()}_select_option` }) }>{props.data.label}</components.Option>
            {(props.isFocused || props.isSelected) && <Icon
              type='check'
              style={{
                position: 'absolute',
                top: 'calc(50% - 6px)',
                right: 12,
                fontWeight: 'bold',
                color: props.isSelected ? undefined : 'rgba(0, 0, 0, 0.87)',
                fontSize: 12,
                textShadow: '0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0',
              }}
              className={props.isSelected ? 'color' : ''}
            />}
          </div>),
          IndicatorSeparator: null,
          DropdownIndicator: () => (
            <div style={{width: 30}}>
              <span className='ant-select-arrow' unselectable='on' style={{userSelect: 'none'}}>
                <Icon type='down' />
              </span>
            </div>
          ),
          MultiValueRemove: (props) => {
            props.innerProps.className = undefined;

            return (<components.MultiValueRemove {...props}>
              <div
                style={{
                  width: 20,
                  color: 'rgba(0, 0, 0, 0.45)',
                }}
                css={css`:hover {color: rgba(0, 0, 0, 0.75) !important}`}
              ><Icon type='close' style={{fontSize: 10, verticalAlign: -3}} /></div>
            </components.MultiValueRemove>)
          },
          MenuList: (props: any) => {
            const { children } = props;
            let list: any;

            if (children && Array.isArray(children)) {
              list = children
                .filter((child: any) => {
                  if (child && child.props && child.props.data && child.props.data.id) {
                    return !child.props.data.id.includes('hidden');
                  }

                  return true;
                })
              if (children.length > (MAX_OPTIONS_COUNT + (enableOptionAll ? 1 : 0))) {
                list = list.slice(0, MAX_OPTIONS_COUNT);
                children[children.length - 1].props.data.label = `... There are ${children.length - 1 - MAX_OPTIONS_COUNT} ${mode === 'multiple' ? label.toLowerCase() : label.toLowerCase().replace(/y$/, 'ie') + 's'} more. Please narrow down the search to find required ${label.toLowerCase()}.`
                list.push(children[children.length - 1]);
              }
            }

            return ( <components.MenuList {...props}>{ list || children}</components.MenuList> );
          }
        }}
        styles={{
          container: (provided, state) => ({
            ...provided,
            paddingBottom: 15,
          }),
          menu: (provided, state) => ({
            ...provided,
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
            marginTop: -12,
          }),
          option: (provided, state) => {
            return {
              ...provided,
              fontSize: 14,
              color: state.isSelected ? 'rgba(0, 0, 0, 0.65)' : provided.color,
              fontWeight: state.isSelected ? 600 : provided.fontWeight,
              backgroundColor: state.isSelected ? '#fafafa' : provided.backgroundColor,
            }
          },
          control: (provided, state) => ({
            ...provided,
            boxShadow: state.isFocused ? `${colors.boxShadowColor} 0 0 0 2px` : undefined,
            borderColor: state.isDisabled ? '#d9d9d9' : provided.borderColor,
            cursor: state.isDisabled ? 'not-allowed' : provided.cursor,
          }),
          multiValueLabel: (provided, state) => ({
            ...provided,
            fontSize: 16,
            color: 'rgba(0, 0, 0, 0.65)',
          }),
          multiValue: (provided, state) => ({
            ...provided,
            backgroundColor: '#fafafa',
            border: '1px solid #e8e8e8',
            borderRadius: 2,
          }),
          singleValue: (provided, state) => ({
            ...provided,
            color: 'rgba(0, 0, 0, 0.65)',
          }),
          multiValueRemove: (provided, state) => ({
            ...provided,
            fontSize: 16,
          }),
        }}
        theme={theme}
      />
    </Form.Item>
  )
}

export default Filter