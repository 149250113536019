/** @jsx jsx */
import {css, jsx} from '@emotion/core'

import {Form, Select} from 'antd'
import {memo} from 'react'

interface GenericFilterProps {
  dataSource?: any[]
  state: any
  onChange: any
}

function FilterCampaignStatus(props: GenericFilterProps) {
  const {dataSource, onChange, state} = props

  const data = dataSource || [
    {id: 'Active', name: 'Active'},
    {id: 'Approved', name: 'Approved'},
    {id: 'Expired', name: 'Expired'},
  ]

  return (
    <div
      css={css`
        .ant-form-item {
          margin-bottom: 0;
        }
      `}
    >
      <Form.Item label='Campaign Status:' data-cy="campaign_status_select_wrapper">
        <Select
          size="large"
          mode="multiple"
          data-cy="campaign_status_select"
          placeholder="Please select"
          value={state['campaign_status']}
          onChange={(value: any[]) => onChange('campaign_status', value)}
          // @ts-ignore
          getPopupContainer={trigger => trigger.parentNode}
        >
          {data.map((item: any) => (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  )
}

export default memo(FilterCampaignStatus)
