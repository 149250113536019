import queryString from 'query-string'

export const text = '#434449'
export const gray = '#f1f2f7'
export const gray10 = '#f1f1f4'
export const gray20 = '#e4e5e9'
export const gray80 = '#6f7077'
export const indigo = '#3f51b5'
export const indigoDarken10 = '#364495'
export const indigoLighten80 = '#b7c1f8'
export const yellow = '#ffc107'
export const green = '#4caf50'
export const danger = '#ef5350'

export const dark = '#333333'
export const base = 'white'

const parsedParameters = queryString.parse(window.location.search) || {};
const hostnameArr = (parsedParameters.redirect || document.location.hostname).toString().split('.')

export const darkColor = hostnameArr[hostnameArr.length-2] === 'inskinmedia' ? '#a5861f' : '#096dd9';
export const mainDarkColor = hostnameArr[hostnameArr.length-2] === 'inskinmedia' ? '#d8c072' : '#1890ff';
export const mainColor = hostnameArr[hostnameArr.length-2] === 'inskinmedia' ? '#e6d79e' : '#40a9ff';
export const highlightingColor = hostnameArr[hostnameArr.length-2] === 'inskinmedia' ? '#fffef0' : '#e6f7ff';
export const boxShadowColor = hostnameArr[hostnameArr.length-2] === 'inskinmedia' ? '#f7f2e1' : '#d0e9ff';
export const linkColor = '#a8a8a8';
