/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Button} from 'antd'
import {navigate} from '@reach/router'

interface PageHeaderProps {
  title: string
  backUrl?: string
  children?: any
  showBackButton?: boolean
}

function PageHeader(props: PageHeaderProps) {
  const {children, backUrl, title, showBackButton = true} = props

  return (
    <div
      style={{
        paddingBottom: 20,
        borderBottom: '1px solid #d5d5d5',
        marginBottom: 20,
      }}
    >
      {showBackButton && (
        <Button
          shape="circle"
          icon="close"
          onClick={() => navigate(backUrl ?? '/')}
          style={{ marginRight: 20, float: 'right' }}
        />
      )}
      <div style={{ fontSize: 20, fontWeight: 700, display: 'inline-block' }}>{title}</div>
      {children}
    </div>
  )
}

export default PageHeader
