import EventBreakdownReport from './event-breakdown'

class CreatorEventBreakdown extends EventBreakdownReport {
  constructor() {
    super('reports:event-breakdown', 'Arc Event Breakdown', 'event-breakdown')
  }

  static get className() {
    return 'CreatorEventBreakdownReport';
  }
}

export default CreatorEventBreakdown
