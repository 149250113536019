import EOCReport from './eoc'

class EOCLiteReport extends EOCReport {
  constructor() {
    super('reports:eoc-lite', 'EOC Report (Lite)', 'eoc-lite')
  }

  static get className() {
    return 'EOCLiteReport';
  }
}

export default EOCLiteReport
