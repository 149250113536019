/** @jsx jsx */
import {jsx} from '@emotion/core'

import {Form, TreeSelect} from 'antd'

const data = [
  {
    title: 'All',
    value: 'viewability',
    key: '0-1',
    children: [
      {
        title: 'In-View %',
        value: 'in_view_pct',
        key: '0-1-0',
      },
      {
        title: 'In-View Time (sec)',
        value: 'in_view_time',
        key: '0-1-1',
      },
      {
        title: 'In-View Time > 1 Min %',
        value: 'in_view_gt_60_pct',
        key: '0-1-2',
      },
      {
        title: 'Total Exposure Time (hr)',
        value: 'exposure_time',
        key: '0-1-3',
      },
    ],
  },
]

// TODO: Separate interface in local types.ts file
interface MetricProps {
  stateKey: string
  state: any
  onChange: any
}

function MetricViewability({stateKey, state, onChange}: MetricProps) {
  return (
    <Form.Item label="Viewability" style={{marginBottom: 0}}>
      <TreeSelect
        id="metrics_viewability_select"
        size="large"
        treeData={data}
        value={state[stateKey]}
        onChange={value => onChange(stateKey, value)}
        treeCheckable={true}
        searchPlaceholder={'Please select'}
      />
    </Form.Item>
  )
}

export default MetricViewability
