/** @jsx jsx */
import {jsx} from '@emotion/core'

import {Form, Switch} from 'antd'
import {useState} from 'react'

interface SwitchGroupProps {
  label: string
  fieldName: string
  dataSource: { type: string, label: string }[]
  onChange: (arg0: string, arg1: any) => void
  defaultValue?: any
}

function SwitchGroup(props: SwitchGroupProps) {
  const {
    label,
    fieldName,
    dataSource,
    onChange,
    defaultValue = []
  } = props;

  const [state, setState] = useState<any | null>(defaultValue);
  const onEventTypeChange = (type: string, checked: boolean) => {
    let value: string[] = state || [];

    if (checked) {
      value.push(type);
    } else {
      value = value.filter(item => item !== type);
    }

    setState(value);
    onChange(fieldName, value);
  };

  return (<Form.Item label={label} style={{marginBottom: 0}}>
    {dataSource.map((item: any, index: number) => (
      <div key={index}>
        <div style={{display: 'inline-block', width: 100, paddingLeft: 20}}>{item.label}</div>
        <Switch
          defaultChecked={defaultValue.includes(item.type)}
          onChange={(checked: boolean) => { onEventTypeChange(item.type, checked); }}
        />
      </div>
    ))}

  </Form.Item>);
}

export default SwitchGroup;
