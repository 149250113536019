/** @jsx jsx */
import {jsx} from '@emotion/core'

import AgencyCampaignsSummaryReport from './agency-campaigns-summary'
import FilterDate from 'src/filters/filter-date'
import {Fragment} from 'react'
import Filter from 'src/filters/filter'
import {Typography} from 'antd'
import MetricGeneral from 'src/metrics/metric-general'
import MetricViewability from 'src/metrics/metric-viewability'
import GroupByFields from 'src/group-by/group-by-fields'
import GroupByDate from 'src/group-by/group-by-date'
import MetricCustom from '../metrics/metric-custom'
import {isInskinAccount} from '../utils/helper'

const {Title} = Typography

class PublisherCampaignsSummaryReport extends AgencyCampaignsSummaryReport {
  constructor(id?: string, name?: string, url?: string, options?: any) {
    const merged_options = {
      // disableDateRangeOptions: []
    };

    if (typeof options === 'object') {
      Object.assign(merged_options, options);
    }

    super(
      id || 'reports:publisher-campaigns-summary',
      name || 'Campaigns Summary Report',
      url || 'publisher-campaigns-summary',
      merged_options
    )
  }

  static get className() {
    return 'PublisherCampaignsSummaryReport';
  }

  getAvailableUsersCampaignsToShowInReport(user?: any) {
    return user.campaigns_raw.filter((campaign: any) => campaign.isPublisherBooked);
  }

  userHasCampaignsToShowInReport(user?: any) {
    const campaigns = this.getAvailableUsersCampaignsToShowInReport(user);

    return campaigns && !!campaigns.length;
  }

  prepareBody({filters, metrics, groupBy, user}: any) {
    const data = super.prepareBody({filters, metrics, groupBy});
    if (!isInskinAccount(user) && this.userHasCampaignsToShowInReport(user)) {
      data.filters.push({
        op: 'in',
        field: 'campaign_id',
        value: this.getAvailableUsersCampaignsToShowInReport(user).map((campaign: any) => campaign.id),
        extra: true
      });
    }

    return data;
  }

  checkIfReportHasInitialState(user?: any) {
    return false;
  }

  renderSidebar({
                  user,
                  filters,
                  metrics,
                  groupBy,
                  onChangeFilter,
                  onChangeMetric,
                  onChangeGroupBy,
                }: any) {
    return (
      <Fragment>
        <Title level={4} style={{marginTop: 20}}>
          General
        </Title>
        <FilterDate
          options={this.options}
          state={filters}
          onChange={onChangeFilter}
        />
        <Filter
          label='Campaigns'
          dataSource={this.getAvailableUsersCampaignsToShowInReport(user)}
          stateKey='campaigns'
          state={filters}
          onChange={onChangeFilter}
        />
        <Title level={4} style={{marginTop: 20}}>
          Metrics
        </Title>
        <MetricGeneral
          stateKey='general'
          state={metrics}
          onChange={onChangeMetric}
        />
        <MetricViewability
          stateKey='viewability'
          state={metrics}
          onChange={onChangeMetric}
        />
        <MetricCustom
          label={'Attention:'}
          stateKey='attention'
          dataSource={[{
            title: 'All',
            value: 'general',
            key: '0-0',
            children: [
              { title: '% of Total Impressions Looked At',
                value: 'pct_total_impressions_looked_at',
                key: '0-0-1' },
              { title: 'Avg. Visual Engagement Time',
                value: 'avg_visual_engagement_time',
                key: '0-0-2' },
              { title: 'Attention per 1000 Impressions',
                value: 'attention_per_1000_impressions',
                key: '0-0-3' },
              { title: 'Total Attention',
                value: 'total_attention_hours',
                key: '0-0-4' }
            ]
          }]}
          state={metrics}
          onChange={onChangeMetric}
        />
        <Title level={4} style={{marginTop: 20}}>
          Group by
        </Title>
        <GroupByFields
          stateKey='group_by'
          state={groupBy}
          onChange={onChangeGroupBy}
          dataSource={[
            {
              title: 'All',
              value: 'all',
              key: '0-0',
              children: [
                {
                  title: 'Campaign',
                  value: 'campaign_name',
                  key: '0-0-0',
                },
                {
                  title: 'Format',
                  value: 'ad_format',
                  key: '0-0-2',
                },
                {
                  title: 'Device',
                  value: 'device',
                  key: '0-0-3',
                },
              ],
            },
          ]}
        />
        <GroupByDate
          stateKey='date_group'
          state={groupBy}
          onChange={onChangeGroupBy}
        />
      </Fragment>
    )
  }


}

export default PublisherCampaignsSummaryReport
