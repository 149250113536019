import React from 'react'
import {useAuth} from './auth-context'
import {AuthenticatedUserPartial} from '../types/types'

interface Props {}

const UserContext = React.createContext<AuthenticatedUserPartial>({})

function UserProvider(props: Props) {
  const {
    data: {user},
  } = useAuth()
  return <UserContext.Provider value={user} {...props} />
}

function useUser() {
  const context = React.useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`)
  }
  return context
}

export {UserProvider, useUser}
