/** @jsx jsx */
import {css, jsx} from '@emotion/core'
import CampaignsSummaryReport from './campaigns-summary'
import FilterDate from 'src/filters/filter-date'
import {Fragment} from 'react'
import Filter from 'src/filters/filter'
import FilterCampaignStatus from 'src/filters/filter-campaign-status'
import {Collapse, Typography} from 'antd'
import MetricGeneral from 'src/metrics/metric-general'
import MetricViewability from 'src/metrics/metric-viewability'
import MetricFinancial from 'src/metrics/metric-financial'
import MetricCreative from 'src/metrics/metric-creative'
import GroupByFields from 'src/group-by/group-by-fields'
import GroupByDate from 'src/group-by/group-by-date'
import {columnSorter, getSiteTitle} from 'src/utils/helper'
import ClassicTable from 'src/components/classic-table'
import PageHeader from 'src/components/page-header'
import ReactSpeedometer, {CustomSegmentLabelPosition} from 'react-d3-speedometer'
import ExportButton from '../components/export-button'
import MetricCustom from '../metrics/metric-custom'

const {Title} = Typography
const {Panel} = Collapse

class AgencyCampaignsSummaryReport extends CampaignsSummaryReport {
  constructor(id?: string, name?: string, url?: string, options?: any) {
    const merged_options = {
      disableDateRangeOptions: ['today', 'yesterday'],
      filterDefaults: {
        campaigns: [],
        date_range: 'last_2_months',
      },
      metricDefaults: {
        general: [
          'booked_impressions',
          'displayed_impressions',
          'clicks',
          'ctr',
          'unique_users',
        ],
        viewability: [
          'in_view_pct',
          'in_view_time',
          'in_view_gt_60_pct',
          'exposure_time',
        ],
        financial: [],
        creative: [],
        attention: [],
      },
      groupByDefaults: {
        group_by: ['campaign_name'],
        date_group: 'lifetime',
      },
    }

    if (typeof options === 'object') {
      Object.assign(merged_options, options)
    }

    super(
      id || 'reports:agency-campaigns-summary',
      name || 'Campaigns Summary Report',
      url || 'agency-campaigns-summary',
      merged_options,
    )
  }

  static get className() {
    return 'AgencyCampaignsSummaryReport'
  }

  userHasCampaignsToShowInReport(user?: any) {
    return !!user.campaigns_raw.length
  }

  checkIfReportHasInitialState(user?: any) {
    return true
  }

  checkIfReportHasDataToFetch(user: any) {
    return false
  }

  get initialReportType(): string|undefined {
    return 'campaigns-summary-dashboard';
  }

  prepareBodyForInitialFetch(...args:any) {
    return { type: this.initialReportType };
  }

  renderSidebar({
                  user,
                  filters,
                  metrics,
                  groupBy,
                  onChangeFilter,
                  onChangeMetric,
                  onChangeGroupBy,
                }: any) {
    return (
      <Fragment>
        <Title level={4} style={{marginTop: 20}}>
          General
        </Title>
        <FilterDate
          options={this.options}
          state={filters}
          onChange={onChangeFilter}
          disabledDaysFromNow={2}
        />
        <Filter
          label="Campaigns"
          dataSource={user.campaigns_raw}
          stateKey="campaigns"
          state={filters}
          onChange={onChangeFilter}
        />
        <FilterCampaignStatus
          dataSource={[
            {id: 'Active', name: 'Active'},
            {id: 'Expired', name: 'Expired'},
          ]}
          state={filters}
          onChange={onChangeFilter}
        />
        <Title level={4} style={{marginTop: 20}}>
          Metrics
        </Title>
        <MetricGeneral
          stateKey="general"
          state={metrics}
          onChange={onChangeMetric}
        />
        <MetricViewability
          stateKey="viewability"
          state={metrics}
          onChange={onChangeMetric}
        />
        <MetricFinancial
          stateKey="financial"
          state={metrics}
          onChange={onChangeMetric}
        />
        <MetricCreative
          label="Video"
          stateKey="creative"
          state={metrics}
          onChange={onChangeMetric}
          dataSource={[
            {
              title: 'All',
              value: 'creative',
              key: '0-3',
              children: [
                {
                  title: 'Video',
                  value: 'interaction_video',
                  key: '0-3-0',
                  children: [
                    {
                      title: 'Video Start',
                      value: 'video_start',
                      key: '0-3-0-1',
                    },
                    {
                      title: 'Video 25%',
                      value: 'video_25',
                      key: '0-3-0-2',
                    },
                    {
                      title: 'Video 50%',
                      value: 'video_50',
                      key: '0-3-0-3',
                    },
                    {
                      title: 'Video 75%',
                      value: 'video_75',
                      key: '0-3-0-4',
                    },
                    {
                      title: 'Video Complete',
                      value: 'video_complete',
                      key: '0-3-0-5',
                    },
                  ],
                },
                {
                  title: 'VTR %',
                  value: 'interaction_vtr',
                  key: '0-3-1',
                },
              ],
            },
          ]}
        />
        <MetricCustom
          label={'Attention:'}
          stateKey="attention"
          dataSource={[{
            title: 'All',
            value: 'general',
            key: '0-0',
            children: [
              {
                title: '% of Total Impressions Looked At',
                value: 'pct_total_impressions_looked_at',
                key: '0-0-1',
              },
              {
                title: 'Avg. Visual Engagement Time',
                value: 'avg_visual_engagement_time',
                key: '0-0-2',
              },
              {
                title: 'Attention per 1000 Impressions',
                value: 'attention_per_1000_impressions',
                key: '0-0-3',
              },
              {
                title: 'Total Attention',
                value: 'total_attention_hours',
                key: '0-0-4',
              },
            ],
          }]}
          state={metrics}
          onChange={onChangeMetric}
        />
        <Title level={4} style={{marginTop: 20}}>
          Group by
        </Title>
        <GroupByFields
          stateKey="group_by"
          state={groupBy}
          onChange={onChangeGroupBy}
          dataSource={[
            {
              title: 'All',
              value: 'all',
              key: '0-0',
              children: [
                {
                  title: 'Campaign',
                  value: 'campaign_name',
                  key: '0-0-0',
                },
                {
                  title: 'Format',
                  value: 'ad_format',
                  key: '0-0-2',
                },
                {
                  title: 'Device',
                  value: 'device',
                  key: '0-0-3',
                },
              ],
            },
          ]}
        />
        <GroupByDate
          stateKey="date_group"
          state={groupBy}
          onChange={onChangeGroupBy}
        />
      </Fragment>
    )
  }

  renderArchivedTable(results: any) {
    const columns = [
      {
        title: 'Campaign',
        dataIndex: 'campaign_name',
        key: 'campaign_name',
        render: (value: string) => value,
        sorter: (a: any, b: any) => columnSorter({field: 'campaign_name'}, a, b),
      },
      {
        title: 'Percentage Delivered',
        dataIndex: 'delivery_pct',
        key: 'delivery_pct',
        render: (value: string) => `${value}%`,
      },
      {
        title: 'CTR',
        dataIndex: 'ctr',
        key: 'ctr',
        render: (value: string) => `${value}%`,
      },
      {
        title: 'Viewability',
        dataIndex: 'viewability_pct',
        key: 'viewability_pct',
        render: (value: string) => `${value}%`,
      },
    ]

    const dataSource = results.rows.filter((item: any) => item.campaign_status === 'Expired')

    return (
      <ClassicTable
        showSearch={false}
        columns={columns}
        dataSource={dataSource}
        onRowEnabled={(): any => true}
        onSearch={(): any => {
        }}
        tableShouldNotBeEmpty={true}
      />
    )
  }

  renderInitialResults({initialResults, user}: any) {
    if (!initialResults) {
      return <div />
    }
    const segment_colors = [
      '#364756',
      '#4c6071',
      '#647a8d',
      '#798fa3',
      '#97a9ba'
    ];
    const stop_color = '#969696';
    const benchmark_color = '#d87272';
    const benchmark_boundaries_half_delta = 0.3;
    const stop_boundaries_delta = 0.1;
    const checkIfIsStop = (b: number) => {
      let temp = Math.round(b % 20 *100)/100;

      if (temp > (20 - benchmark_boundaries_half_delta) && temp < 20) {
        return Math.floor(b + benchmark_boundaries_half_delta);
      }
      if (temp >=0 && temp < (benchmark_boundaries_half_delta + stop_boundaries_delta)) {
        return Math.floor(b);
      }

      return false;
    }

    return (
      <div style={{margin: 20, width: '100%'}} data-cy={'agency_dashboard'}>
        <PageHeader title={`Welcome to ${getSiteTitle()}`} showBackButton={false} />
        {initialResults.result.rows.length ? (
          <div>
            {initialResults.result.rows.filter(
              (item: any) => item.campaign_status === 'Active',
            ).length ? (
              <Collapse defaultActiveKey={['0']} style={{marginBottom: 20}}>
                {initialResults.result.rows
                  .filter((item: any) => item.campaign_status === 'Active')
                  .map((item: any, index: number) => {
                    const benchmark = item.viewability_benchmark < 0 ? 0 : (item.viewability_benchmark > 100 ? 100 : item.viewability_benchmark);
                    const benchmark_boundaries = [
                      Math.round((benchmark - benchmark_boundaries_half_delta)*100)/100,
                      Math.round((benchmark + benchmark_boundaries_half_delta)*100)/100
                    ]
                    const colors: string[] = [];
                    const labels: any[] = [];
                    let stops = [
                      0, stop_boundaries_delta,
                      20, 20 + stop_boundaries_delta,
                      40, 40 + stop_boundaries_delta,
                      60, 60 + stop_boundaries_delta,
                      80, 80 + stop_boundaries_delta,
                      100, 100 + stop_boundaries_delta];

                    benchmark_boundaries.forEach(benchmark_boundary => {
                      if (!stops.includes(benchmark_boundary)) {
                        stops.push(benchmark_boundary)
                      }
                    });
                    stops = stops.reduce((acc: number[], stop: number) => {
                      if (stop > benchmark_boundaries[0] && stop < benchmark_boundaries[1]) {
                        return acc;
                      }
                      if (stop > 100 + stop_boundaries_delta) {
                        acc.push(100 + stop_boundaries_delta);
                        return acc;
                      }
                      if (stop < 0) {
                        acc.push(0);
                        return acc;
                      }

                      acc.push(stop);
                      return acc;
                    }, []);
                    stops = stops.sort((a,b) => a - b);
                    stops.forEach((stop, i) => {
                      const next_stop = stops[i + 1];

                      if (!next_stop) {
                        return;
                      }

                      if ((stop === benchmark_boundaries[0] || stop === 0) && (next_stop === benchmark_boundaries[1] || next_stop === (100 + stop_boundaries_delta))) {
                        colors.push(benchmark_color);
                        // labels.push({ text: (Math.round(benchmark*100)/100).toString(), position: CustomSegmentLabelPosition.Outside, fontSize: '20px', color: benchmark_color });
                        labels.push({ text: checkIfIsStop(benchmark) || '', position: CustomSegmentLabelPosition.Outside });

                        return;
                      }
                      if (stop % 20 === 0 && Math.round((next_stop % 20)*100)/100 === stop_boundaries_delta) {
                        colors.push(stop_color);
                        labels.push({ text: stop.toString(), position: CustomSegmentLabelPosition.Outside });

                        return;
                      }

                      labels.push({ text: '' });

                      if (next_stop <= 20) {
                        colors.push(segment_colors[0]);
                        return;
                      }
                      if (next_stop <= 40) {
                        colors.push(segment_colors[1]);
                        return;
                      }
                      if (next_stop <= 60) {
                        colors.push(segment_colors[2]);
                        return;
                      }
                      if (next_stop <= 80) {
                        colors.push(segment_colors[3]);
                        return;
                      }
                      if (next_stop <= 100) {
                        colors.push(segment_colors[4]);
                        return;
                      }
                    });

                    if (stops[stops.length - 1] === (100 + stop_boundaries_delta)) {
                      stops[stops.length - 1] = 100;
                      if (stops[stops.length - 2] === 100) {
                        stops[stops.length - 2] = 100 - stop_boundaries_delta;
                      }
                    }

                    return (<Panel header={item.campaign_name} key={String(index)} extra={
                      <ExportButton
                        user={user}
                        reportFilter={{campaigns: [item.campaign_id]}}
                        reportInstance={this}
                      />
                    }
                    css={css`
                      .thirdPart {
                        display: inline-grid;
                        width: 33.33%;
                        text-align: center;
                        justify-content: center;                        
                      }
                    `}

                    >
                      <div className={'thirdPart'} >
                        <Title level={3}>Percentage Delivered</Title>
                        <ReactSpeedometer
                          minValue={0}
                          maxValue={100}
                          height={175}
                          width={300}
                          paddingHorizontal={5}
                          segments={5}
                          maxSegmentLabels={5}
                          segmentColors={[
                            '#4c2147',
                            '#693062',
                            '#82497b',
                            '#985990',
                            '#a96aa1',
                          ]}
                          // eslint-disable-next-line no-template-curly-in-string
                          currentValueText={'${value} %'}
                          needleColor={'#333333'}
                          value={item.delivery_pct <= 100 ? item.delivery_pct : 100}
                        />
                      </div>
                      <div className={'thirdPart'}>
                        <Title level={3}>Viewability</Title>
                        <ReactSpeedometer
                          minValue={0}
                          maxValue={100}
                          height={175}
                          width={300}
                          paddingHorizontal={5}
                          value={
                            item.viewability_pct <= 100 ? item.viewability_pct : 100
                          }
                          segmentColors={colors}
                          customSegmentStops={stops}
                          customSegmentLabels={labels}
                          needleColor={'#333333'}
                          // eslint-disable-next-line no-template-curly-in-string
                          currentValueText={'${value} %'}
                        />
                        <span style={{textAlign: 'left', fontWeight: 700, color: benchmark_color, paddingLeft: 5}}>{`* Benchmark: ${benchmark} %`}</span>
                      </div>
                      <div className={'thirdPart'}>
                        <Title level={3}>CTR</Title>
                        <span
                          css={{
                            fontSize: 60,
                            width: 300,
                            fontWeight: 'bold',
                            color: '#c4c4c4',
                            border: '2px solid #c4c4c4',
                            padding: '30px 40px',
                          }}
                        >
                            {`${item.ctr} %`}
                          </span>
                      </div>
                    </Panel>);
                })}
              </Collapse>
            ) : null}
          </div>
        ) : null}
        {initialResults.result.rows.length ? (
          <div>
            {initialResults.result.rows.filter(
              (item: any) => item.campaign_status === 'Expired',
            ).length ? (
              <Collapse defaultActiveKey={['0']}>
                <Panel header={'Archived Campaigns'} key={'archived_panel'} extra={
                  <ExportButton
                    user={user}
                    reportFilter={{campaign_status: ['Expired']}}
                    reportInstance={this}
                  />
                }>
                  {this.renderArchivedTable(initialResults.result)}
                </Panel>
              </Collapse>
            ) : null}
          </div>
        ) : null}
      </div>
    )
  }
}

export default AgencyCampaignsSummaryReport
