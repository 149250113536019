/** @jsx jsx */
import {jsx, css} from '@emotion/core'

import {Form, Select, InputNumber} from 'antd'
import {memo} from 'react'
import FilterDateRange from './filter-date-range'

const filterOptionsToReport = (options: any, dataSource?: any[]) => {
  const generateSelectOption = (item: any) => <Select.Option key={item.key} value={item.key}>{item.name}</Select.Option>;
  let defaultSelectOptions: any = [
    { key: 'last_2_months', name: 'Last 2 months' },
    { key: 'today', name: 'Today' },
    { key: 'yesterday', name: 'Yesterday' },
    { key: 'date_to_date', name: 'Specific date range' },
    { group: 'now', name: 'Now', options: [
        { key: 'this_week', name: 'This week' },
        { key: 'this_month', name: 'This month' },
        { key: 'this_quarter', name: 'This quarter' },
        { key: 'this_year', name: 'This year' },
      ]
    },
    { group: 'last', name: 'Previous' , options: [
        { key: 'last_week', name: 'Previous week' },
        { key: 'last_month', name: 'Previous month' },
        { key: 'last_quarter', name: 'Previous quarter' },
        { key: 'last_year', name: 'Previous year' },
        { key: 'last_n_days', name: 'Previous \'N\' days' },
      ]
    },
    { key: 'lifetime', name: 'All time' }
  ];

  if (options.disableDateRangeOptions) {
    let disableDateRangeOptions = options.disableDateRangeOptions;

    if (!Array.isArray(disableDateRangeOptions)) {
      disableDateRangeOptions = [disableDateRangeOptions];
    }

    if (disableDateRangeOptions.length > 0) {
      defaultSelectOptions = defaultSelectOptions.reduce((acc: any[], item: any) => {
        if (options.disableDateRangeOptions.includes(item.key)) {
          return acc;
        }

        if (item.group) {
          if (item.options.filter((i: any) => !options.disableDateRangeOptions.includes(i.key)).length === 0) {
            return acc;
          }

          acc.push({ ...item, options: item.options.filter((i: any) => !options.disableDateRangeOptions.includes(i.key)) });
          return acc;
        }

        acc.push(item);

        return acc;
      }, [])
    }
  }
  defaultSelectOptions = defaultSelectOptions.map((item: any) => item.group
    ? <Select.OptGroup key={item.group} label={item.name}>{item.options.map(generateSelectOption)}</Select.OptGroup>
    : generateSelectOption(item)
  );

  return dataSource
    ? dataSource.map(o => typeof o.option !== 'undefined' ? o.option : o)
    : defaultSelectOptions;
}

interface FilterDateProps {
  dataSource?: any[]
  options: any
  state: any
  onChange: any
  disabledDaysFromNow?: number
}

function FilterDate(props: FilterDateProps) {
  const {dataSource, options, onChange, state, disabledDaysFromNow} = props

  const {date_range, now_minus_n_days_value} = state

  return (
    <div
      css={css`
        .ant-form-item {
          margin-bottom: 0;
        }
      `}
    >
      <Form.Item label="Date:" data-cy="date_select_wrapper">
        <Select
          data-cy="date_select"
          size="large"
          style={{paddingBottom: 15}}
          value={date_range}
          onSelect={(value: string) => onChange('date_range', value)}
          // @ts-ignore
          getPopupContainer={trigger => trigger.parentNode}
        >
          {filterOptionsToReport(options, dataSource)}
        </Select>
      </Form.Item>
      {date_range === 'last_n_days' && (
        <Form.Item label="Enter number of days">
          <InputNumber
            size="large"
            min={1}
            data-cy="date_select_input_n_days"
            style={{width: '100%'}}
            value={now_minus_n_days_value}
            placeholder={'Enter number of days'}
            onChange={(value: any) => onChange('now_minus_n_days_value', value)}
          />
        </Form.Item>
      )}
      {date_range === 'date_to_date' && (
        <FilterDateRange
          state={state}
          onChange={(key: string, value: any) => onChange(key, value)}
          disabledDaysFromNow={disabledDaysFromNow}
        />
      )}
    </div>
  )
}

export default memo(FilterDate)
