/** @jsx jsx */
import {css, jsx} from '@emotion/core'
import {Typography, Button, Alert} from 'antd'
import LoadingSpinner from 'src/components/loading-spinner'
import {
  columnSorter,
  commonValidateFiltersInputRules,
  getMaxColumns, getSiteTitle, isInskinAccount,
  isSchedulerEnabled,
  onOpenURL,
} from 'src/utils/helper'
import {useUser} from '../context/user-context'
import moment from 'moment-timezone'
import ClassicTable from '../components/classic-table'
import {API_URL} from '../utils/api-client'

const {Title} = Typography
export abstract class Report {
  protected constructor(
    public id: string,
    public name: string,
    public url: string,
    public options: any
  ) {
    this.id = id;
    this.name = name;
    this.url = url;
    this.options = options;
  }

  userHasCampaignsToShowInReport() {
    return true;
  }

  checkIfReportHasInitialState() {
    return false;
  }

  renderWelcome(): JSX.Element {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          height: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          lineHeight: '1.8rem',
        }}
      >
        <div style={{fontSize: 24, fontWeight: 700}}>
          {`Welcome to ${getSiteTitle()}`}
        </div>
        <br />
        {'Customise your report by using filters on a sidebar.'}
        <br />
        {'Click Generate to see your report, please note this may take a few minutes to generate.'}
        <br />
        {'You can export the report by using the button in the top right of the report.'}
      </div>
    )
  }

  renderLoading(): JSX.Element {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{marginBottom: 10, textAlign: 'center'}}>
          <Title level={4}>
            {'Generating report'}
            <br />
            {'This may take a few minutes'}
          </Title>
        </div>
        <LoadingSpinner />
      </div>
    )
  }

  abstract prepareBody(args: any): any
  prepareBodyForInitialFetch?(args: any): any
  get initialReportType(): string|undefined {
    return undefined
  }
  renderInitialResults?(args: any): JSX.Element
  validateFiltersInputRules(args: any): boolean | string[] {
    return commonValidateFiltersInputRules(args);
  }

  onExport(reportId: number, type: string = 'csv'): any {
    const token = window.localStorage.getItem('__inskin_reports_token__')
    const url = `${API_URL}/download/${reportId}?type=${type}&jwt=${token}`
    onOpenURL(url)
  }

  checkIfReportHasDataToFetch(user: any) {
    return isInskinAccount(user);
  }

  fetchFiltersData(...args: any): any {
    return {};
  }

  abstract renderSidebar({setFilter}: any): JSX.Element

  prepareTableColumns (results: any) {
    const maxColumnCount = getMaxColumns();
    const columnCount = results.result.captions.length;
    const isTableLarge = columnCount > maxColumnCount;

    const columns: any[] =
      (isTableLarge ? results.result.captions.slice(0, maxColumnCount) : results.result.captions)
        .reduce(
          (accumulator: any[], column: any) => {
            accumulator.push({
              title: column.caption,
              dataIndex: column.field,
              key: column.field,
              render: (value: any) => (value && value.text) ? value.text : value,
              sorter: (a: any, b: any) => {
                let func = columnSorter(column, a, b)

                this.orderColumns.forEach(( col) => {
                  if (typeof col === 'object' && column.field === col.name) {
                    func = columnSorter({field: col.name}, a, b, col.normalizer)
                  }
                }, {})

                return func
              }
            })
            return accumulator
          },
          []
        )

    if (isTableLarge) {
      columns.push({
        title: '...',
        dataIndex: 'placeholder',
        key: 'placeholder',
        render: () => '...',
        width: 65
      });
    }

    return columns;
  }

  getElementBeforeTable() : JSX.Element | null {
    return null;
  }

  renderResults({ results, tableShouldNotBeEmpty = false }: any, options?: any): JSX.Element {
    const { rows_total, captions } = results.result;
    const footerSource = rows_total ? this.prepareFooterSource(rows_total, captions[0] && captions[0].field) : null
    const maxColumnCount = getMaxColumns();
    const columnCount = results.result.captions.length;
    const isTableLarge = columnCount > maxColumnCount;

    return (
      <div
        className="reportResults"
        css={css`.ant-alert i { top: 21px }`}
      >
        {isTableLarge ? (<Alert
          type='info'
          message={'Table has too many columns to show.'}
          description={'Please use the export buttons to see the whole report.'}
          showIcon
          // closable
          style={{ marginBottom: 15, display: 'grid', alignItems: 'center' }}
        />) : null}
        {this.getElementBeforeTable()}
        <ClassicTable
          columns={this.prepareTableColumns(results)}
          dataSource={results.result.rows}
          footerSource={footerSource}
          actions={this.renderToolbar(results, options)}
          onRowEnabled={() => true}
          onSearch={query => options.onSearch(query)}
          tableShouldNotBeEmpty={tableShouldNotBeEmpty}
          tableMaxHeightCSS={`calc( 100vh - 364px${isTableLarge && ' - 97px'} )`}
        />
      </div>
    )
  }

  orderResultRows(result: any) {
    if (result.rows) {
      const fieldSorter = (fields: any[]) => (a: any, b: any) => fields
        .map((field) => {
          if (typeof field === 'object') {
            return columnSorter({field: field.name}, a, b, field.normalizer)
          }

          return columnSorter({ field }, a, b)
        })
        .reduce((p,n) => p ? p : n, 0)

      const orderColumns = this.orderColumns.reduce((accum: any[], orderColumn: any) => {
        if (result.captions
          .map(({ field }: any) => field)
          .includes((orderColumn && orderColumn.name) || orderColumn))
        {
          accum.push(orderColumn);
        }

        return accum;
      }, [])

      result.rows = result.rows.sort(fieldSorter(orderColumns));
    }

    return result;
  }

  formatResultRows(result: any) {
    if (!result.rows) {
      return result
    }

    result.rows = result.rows.map((row: any) => {
      Object.keys(row).forEach((key: string) => {
        switch (key) {
          case 'campaign_actual_start_date':
          case 'campaign_end_date':
          case 'campaign_start_date':
          case 'date_utc':
          case 'day':
          case 'day_hour_utc':
          case 'end_time_utc':
          case 'start_time_utc':
            if (typeof row[key] === 'string' && !isNaN(Date.parse(row[key]))) {
              row[key] = {
                value: new Date(row[key]),
                text: moment(row[key]).format('DD/MM/YYYY')
              };
            }
            break;
          case 'week':
            row[key] = {
              value: new Date(row[key].substr(0, 10)),
              text: `${moment(row[key].substr(0, 10)).format('DD/MM/YYYY')} - ${moment(row[key].substr(-10)).format('DD/MM/YYYY')}`
            };
            break;
        }
      })

      return row;
    })

    return result;
  }

  get orderColumns(): any[] {
    return [];
  }

  renderToolbar(results: any, options?: any): any[] {
    const buttons = [];
    if (isSchedulerEnabled(useUser())) {
      buttons.push(<Button
        type="primary"
        icon="calendar"
        size="large"
        onClick={() => options.onSchedule(results)}
      >
        Schedule
      </Button>);
    }

    buttons.push(...[
      <Button
        type="primary"
        icon="export"
        size="large"
        onClick={() => this.onExport(results.id, 'csv')}
      >
        Export to CSV
      </Button>,
      <Button
        type="primary"
        icon="export"
        size="large"
        onClick={() => this.onExport(results.id, 'xlsx')}
      >
        Export to Excel
      </Button>
    ]);

    return buttons
  }

  prepareFooterSource(row: object, first_column_name: string): object {
    const result: { [index: string] : any } = Object.assign({}, row);

    result[first_column_name] = result[first_column_name] || 'TOTAL';

    return result;
  }
}

// Example: createInstance(Foo, "Hello World", 2).bar();
// export function createInstance<A extends Report>(
//   c: new (...args: any[]) => A,
//   ...args: any[]
// ): A {
//   return new c(...args)
// }
const reportsInstances: any = {};
export function createInstance(constructor: any, ...args: any[]) {
  if (!reportsInstances[constructor.className]) {
    reportsInstances[constructor.className] = new constructor(...args);
  }

  return reportsInstances[constructor.className];
}