import React from 'react'
import ReactDOM from 'react-dom'
import {LocationProvider} from '@reach/router'
import App from './App'
import AppProviders from './context'
import * as serviceWorker from './serviceWorker'
import {getSiteTitle} from './utils/helper'
import queryString from 'query-string'
import * as colors from './styles/colors'

ReactDOM.render(
  <LocationProvider>
    <AppProviders>
      <App />
    </AppProviders>
  </LocationProvider>,
  document.getElementById('root'),
)

const parsedParameters = queryString.parse(window.location.search) || {}
const hostnameArr = (parsedParameters.redirect || document.location.hostname).toString().split('.')

hostnameArr[0] = hostnameArr[0].replace(/(^\w+:|^)\/\//, '')

let app = (hostnameArr[0] === 'tools' || hostnameArr[0] === 'auth') ? 'general' : hostnameArr[0]
const domain = hostnameArr[hostnameArr.length - 2]?.includes('azerion') ? 'acp' : hostnameArr[hostnameArr.length - 2]

if (domain === 'acp') {
  app = 'general'
}
if (domain === 'inskinmedia') {
  document.body.className = 'inskin-reports-theme'
}
document.title = getSiteTitle(hostnameArr[0], domain)
// @ts-ignore
document.getElementById('description-placeholder').content = ''
// @ts-ignore
document.getElementById('apple-touch-icon-placeholder').href = `/favicons/${domain}/${app}/apple-touch-icon.png`
// @ts-ignore
document.getElementById('favicon-32-placeholder').href = `/favicons/${domain}/${app}/favicon-32x32.png`
// @ts-ignore
document.getElementById('favicon-16-placeholder').href = `/favicons/${domain}/${app}/favicon-16x16.png`
// @ts-ignore
document.getElementById('safari-pinned-tab-placeholder').href = `/favicons/${domain}/${app}/safari-pinned-tab.svg`
// @ts-ignore
document.getElementById('safari-pinned-tab-placeholder').color = colors.mainDarkColor
// @ts-ignore
document.getElementById('msapplication-TileColor-placeholder').color = colors.mainDarkColor
// @ts-ignore
document.getElementById('browserconfig-placeholder').content = `/favicons/${domain}/${app}/browserconfig.xml`

if (domain === 'acp') {
  // @ts-ignore
  let el = document.getElementById('description-placeholder')
  if (el) el.remove()
// @ts-ignore
  el = document.getElementById('apple-touch-icon-placeholder')
  if (el) el.remove()
// @ts-ignore
  el = document.getElementById('favicon-32-placeholder')
  if (el) el.remove()
// @ts-ignore
  el = document.getElementById('favicon-16-placeholder')
  if (el) el.remove()
// @ts-ignore
  el = document.getElementById('safari-pinned-tab-placeholder')
  if (el) el.remove()
// @ts-ignore
  el = document.getElementById('safari-pinned-tab-placeholder')
  if (el) el.remove()
// @ts-ignore
  el = document.getElementById('msapplication-TileColor-placeholder')
  if (el) el.remove()
// @ts-ignore
  el = document.getElementById('browserconfig-placeholder')
  if (el) el.remove()
// @ts-ignore
  document.getElementById('favicon-placeholder').href = `/favicons/${domain}/${app}/favicon.ico`
} else {
// @ts-ignore
  document.getElementById('favicon-placeholder').remove()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
