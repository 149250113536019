/** @jsx jsx */
import {jsx} from '@emotion/core'

import {Form, Select} from 'antd'

const data = [
  {name: 'All time', id: 'lifetime'},
  {name: 'Day', id: 'day'},
  {name: 'Day of week', id: 'day_of_week'},
  {name: 'Week', id: 'week'},
  {name: 'Month', id: 'month'},
  {name: 'Year', id: 'year'},
]

// TODO: Separate interface in local types.ts file
interface GroupByProps {
  stateKey: string
  state: any
  onChange: any
}

function GroupByDate({stateKey, state, onChange}: GroupByProps) {
  return (
    <Form.Item label='Date' style={{marginBottom: 0}} data-cy='group_by_date_select_wrapper'>
      <Select
        size='large'
        data-cy='group_by_date_select'
        value={state[stateKey]}
        onSelect={(value: string) => onChange(stateKey, value)}
        // @ts-ignore
        getPopupContainer={trigger => trigger.parentNode}
      >
        {data.map((item: any) => (
          <Select.Option key={item.id} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default GroupByDate
