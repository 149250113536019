/** @jsx jsx */
import {jsx} from '@emotion/core'

import {Report} from './abstract'
import FilterYears from 'src/filters/filter-years'
import {Fragment} from 'react'
import {Typography, Button, Divider} from 'antd'
import ClassicTable from 'src/components/classic-table'
import {onOpenURL} from 'src/utils/helper'
import {API_URL} from '../utils/api-client'

const {Title} = Typography

class POReport extends Report {
  constructor() {
    super('reports:po', 'Purchase Order', 'po', {
      filterDefaults: {
        period_year: new Date().getFullYear(),
      },
    })
  }

  static get className() {
    return 'POReport';
  }

  validateRules() {
    return true
  }

  prepareBody({filters, metrics, groupBy}: any) {
    const customFilters = []
    if (filters.site_name) {
      customFilters.push({
        op: '=',
        field: 'site_id',
        value: filters.site_name,
      })
    }
    if (filters.section_name) {
      customFilters.push({
        op: '=',
        field: 'section_id',
        value: filters.site_name,
      })
    }

    const data = {
      type: this.id?.split(':')[1], // TODO: Make it better through currentReportType
      filters: customFilters,
    }

    return data
  }

  renderSidebar({filters, onChangeFilter}: any) {
    return (
      <Fragment>
        <Title level={4} style={{marginTop: 20}}>
          General
        </Title>
        <FilterYears state={filters} onChange={onChangeFilter} />
      </Fragment>
    )
  }

  renderToolbar(results: any) {
    return []
  }

  onDownload = (period: string, type = 'csv') => {
    // TODO: Use token from user instance directly
    const token = window.localStorage.getItem('__inskin_reports_token__')
    const url = `${API_URL}/po/download?period=${period}&type=${type}&jwt=${token}`
    onOpenURL(url)
  }

  renderResults({ results, tableShouldNotBeEmpty = false }: any) {
    const fileTypes = ['csv', 'xlsx', 'pdf']

    const columns = [
      {
        title: 'Period',
        dataIndex: 'period',
        key: 'period',
        render: (value: string) => value,
      },
      {
        title: 'Download',
        width: '50%',
        render: (_value: any, record: any) => (
          <Fragment>
            {fileTypes.map((type, index) => (
              <Fragment key={index}>
                <Button
                  disabled={!record.types.includes(type)}
                  style={{width: 65}}
                  onClick={() => this.onDownload(record.period, type)}
                >
                  {type.toUpperCase()}
                </Button>
                {index !== fileTypes.length - 1 && <Divider type="vertical" />}
              </Fragment>
            ))}
          </Fragment>
        ),
      },
    ]

    return (
      <div style={{margin: 20, width: '100%'}}>
        <ClassicTable
          showSearch={false}
          columns={columns}
          dataSource={results}
          onRowEnabled={(): any => true}
          onSearch={(): any => {}}
          tableShouldNotBeEmpty={tableShouldNotBeEmpty}
        />
      </div>
    )
  }

  generateReports(): void {
    console.log('Generating accounting reports...')
  }
}

export default POReport
