/** @jsx jsx */
import {css, jsx} from '@emotion/core'

import {Report} from './abstract'
import FilterDate from 'src/filters/filter-date'
import {Fragment} from 'react'
import {Typography, Select, Button} from 'antd'
import Filter from 'src/filters/filter'

import Image from '../assets/no_creatives.svg'
import {onOpenURL, formatDateRange, isInskinAccount} from 'src/utils/helper'
import {useUser} from '../context/user-context'
import * as genericClient from '../utils/generic-api'

const {Title} = Typography

class EOCReport extends Report {
  constructor(id?: string, name?: string, url?: string, options?: any) {
    const merged_options = {
      enabledDateRangeOptions: ['lifetime', 'date_to_date'], // TODO: Replace with NULL and check without enabledDateRangeOptions.length
      filterDefaults: {
        campaigns: [],
        date_range: 'lifetime',
      },
      metricDefaults: {
        general: [],
        viewability: [],
        financial: [],
        creative: [],
      },
      groupByDefaults: {
        group_by: ['campaign_name', 'line_item_name'],
        date_group: 'lifetime',
      },
    };

    if (typeof options === 'object') {
      Object.assign(merged_options, options);
    }

    super(
      id || 'reports:eoc',
      name || 'EOC Report',
      url || 'eoc',
      merged_options
    )
  }

  static get className() {
    return 'EOCReport';
  }

  get fileTypesEnabled() {
    return ['PPTX', 'PDF'];
  }

  get shouldShowOpenReportButton() {
    return isInskinAccount(useUser());
  }

  validateFiltersInputRules({filters}: any) {
    if (!filters.campaigns || filters.campaigns.length === 0) {
      return ['Please select a campaign from the list']
    }
    return true
  }

  prepareBody({filters}: any) {
    const data = {
      type: this.id?.split(':')[1], // TODO: Make it better through currentReportType
      dateRange: formatDateRange(filters.date_range, filters),
      filters: [
        {
          op: '=',
          field: 'campaign_id',
          value: filters.campaigns,
        },
      ],
      fields: [],
    }

    return data
  }

  async fetchFiltersData(signal: any) {
    let filtersData = {};

    try {
      filtersData = {
        campaigns: (await genericClient.search('campaigns?$or[0][isISAP]=true&$or[1][isImproveDigital]=true&$order=-endDate', '', signal)).data,
      };
    } catch (e: any) {
      if (signal.aborted) {
        console.log('[fetchFiltersData] ABORTED!')
      }
    }

    return filtersData;
  }

  renderSidebar({
                  user,
                  filters,
                  filtersData,
                  onChangeFilter}: any
  ) {
    return (
      <Fragment>
        <Title level={4} style={{marginTop: 20}}>
          General
        </Title>
        <FilterDate
          dataSource={[
            {
              key: 'date_to_date',
              option: (
                <Select.Option key="date_to_date" value="date_to_date">
                  Specific date range
                </Select.Option>
              ),
            },
            {
              key: 'lifetime',
              option: (
                <Select.Option key="lifetime" value="lifetime">
                  All time
                </Select.Option>
              ),
            },
          ]}
          options={this.options}
          state={filters}
          onChange={onChangeFilter}
        />
        <Filter
          mode={'default'}
          label="Campaign"
          // url="campaigns?$order=-id"
          initialLoading={this.checkIfReportHasDataToFetch(user) ? !filtersData.campaigns : undefined}
          dataSource={this.checkIfReportHasDataToFetch(user) ? filtersData.campaigns : user.campaigns_raw}
          stateKey="campaigns"
          state={filters}
          onChange={onChangeFilter}
        />
      </Fragment>
    )
  }

  renderToolbar(results: any) {
    return []
  }

  renderResults({ results }: any) {
    const document_meta = results.result.document_meta;

    return (
      <div
        data-cy="report_ready_block"
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          height: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={Image} alt={'Logo'} width={150} />
        <div style={{marginTop: 15, fontSize: 20, fontWeight: 700}}>
          The report is ready
        </div>
        <div
          style={{marginTop: 15}}
          css={css`
            button {
              margin-right: 5px
            }
            button:last-child {
              margin-right: 0px
            }
          `}
        >
          {this.shouldShowOpenReportButton && <Button
            size="large"
            onClick={() => onOpenURL(document_meta.url)}
          >
            Open the report
          </Button>}
          {document_meta.supported_formats && Array.isArray(document_meta.supported_formats)
          && this.fileTypesEnabled.reduce((buttons:any[],type:string) => {
            if (document_meta.supported_formats.includes(type.toLowerCase())) {
              buttons.push(<Button
                // type="primary"
                // icon="export"
                size="large"
                onClick={() => this.onExport(results.id, type.toLowerCase())}
              >
                {`Export to ${type.toUpperCase()}`}
              </Button>)
            }
            return buttons;
          }, [])}
        </div>
      </div>
    )
  }

  generateReports(): void {
    console.log('Generating accounting reports...')
  }
}

export default EOCReport
