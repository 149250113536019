/** @jsx jsx */
import {jsx} from '@emotion/core'

import {Form, TreeSelect} from 'antd'

const default_data = [
  {
    title: 'All',
    value: 'all',
    key: '0-0',
    children: [
      {
        title: 'Agency',
        value: 'agency_name',
        key: '0-0-5',
      },
      {
        title: 'Campaign',
        value: 'campaign_name',
        key: '0-0-0',
      },
      {
        title: 'Line Item',
        value: 'line_item_name',
        key: '0-0-1',
      },
      {
        title: 'Format',
        value: 'ad_format',
        key: '0-0-2',
      },
      {
        title: 'Device',
        value: 'device',
        key: '0-0-3',
      },
      {
        title: 'Creative',
        value: 'creative_name',
        key: '0-0-4',
      },
    ],
  },
]

// TODO: Separate interface in local types.ts file
interface GroupByProps {
  dataSource?: any[]
  stateKey: string
  state: any
  onChange: any
}

function GroupByFields({dataSource, stateKey, state, onChange}: GroupByProps) {
  return (
    <Form.Item label='Fields' style={{marginBottom: 0}}>
      <div data-cy="group_by_fields_select_wrapper">
        <TreeSelect
          size='large'
          id='group_by_fields_select'
          className={"test"}
          data-id='group_by_fields_select'
          treeData={dataSource || default_data}
          value={state[stateKey]}
          onChange={value => onChange(stateKey, value)}
          treeCheckable={true}
          searchPlaceholder={'Please select'}
        />
      </div>
    </Form.Item>
  )
}

export default GroupByFields
