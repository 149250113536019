/** @jsx jsx */
import {jsx, css} from '@emotion/core'

import {Form, Select} from 'antd'
import {memo} from 'react'

interface GenericFilterProps {
  dataSource?: any[]
  state: any
  onChange: any
}

function FilterYears(props: GenericFilterProps) {
  const {dataSource, onChange, state} = props
  const years = [];

  for (let i = 2017; i <= new Date().getFullYear(); i++) {
    years.push(i);
  }

  const data = dataSource
    || years.map((year: number) => ({ id: year, name: `${year}`}))

  return (
    <div
      css={css`
        .ant-form-item {
          margin-bottom: 0;
        }
      `}
    >
      <Form.Item label="Year:">
        <Select
          size="large"
          showSearch={false}
          mode="default"
          placeholder="Please select"
          value={state['period_year']}
          onChange={(value: any[]) => onChange('period_year', value)}
          // @ts-ignore
          getPopupContainer={trigger => trigger.parentNode}
        >
          {data.map((item: any) => (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  )
}

export default memo(FilterYears)
