/** @jsx jsx */
import {jsx} from '@emotion/core'

import {Form, TreeSelect} from 'antd'

const data = [
  {
    title: 'All',
    value: 'financial',
    key: '0-2',
    children: [
      {
        title: 'CPM',
        value: 'line_item_gross_cpm',
        key: '0-2-0',
      },
      {
        title: 'Media Spend',
        value: 'media_spend',
        key: '0-2-1',
      },
    ],
  },
]

// TODO: Separate interface in local types.ts file
interface MetricProps {
  stateKey: string
  state: any
  onChange: any
}

function MetricFinancial({stateKey, state, onChange}: MetricProps) {
  return (
    <Form.Item label="Financial" style={{marginBottom: 0}}>
      <TreeSelect
        size="large"
        treeData={data}
        value={state[stateKey]}
        onChange={value => onChange(stateKey, value)}
        treeCheckable={true}
        searchPlaceholder={'Please select'}
      />
    </Form.Item>
  )
}

export default MetricFinancial
