/** @jsx jsx */
import {jsx, css} from '@emotion/core'

import {Form, DatePicker} from 'antd'
import {memo} from 'react'
import moment from 'moment'

const RangePicker = DatePicker.RangePicker

const minDate = moment('2017-07-01').startOf('day')

interface FilterDateRangeProps {
  state: any
  onChange: any
  disabledDaysFromNow?: number
}

function FilterDateRange(props: FilterDateRangeProps) {
  const {onChange, state, disabledDaysFromNow = 0} = props
  const disabledDate = (current: any) =>
    (current && current < minDate) ||
    current >
    moment()
      .endOf('day')
      .subtract(disabledDaysFromNow, 'day')
  const ranges = [
    { Today: [moment(), moment()] },
    { Yesterday: [moment().subtract(1, 'day'), moment().subtract(1, 'day')] },
    { 'This Month': [moment().startOf('month'), moment().subtract(1, 'day')] },
    { 'Previous Month': [
        moment()
          .subtract(1, 'months')
          .startOf('month'),
        moment()
          .subtract(1, 'months')
          .endOf('month'),
      ] }
  ].reduce((acc, range, i) => {
    if (i > (disabledDaysFromNow - 1) || i > 2) {
      Object.assign(acc, range);
    }

    return acc;
  }, {})
  const {date_from, date_to} = state

  return (
    <div
      css={css`
        .ant-form-item {
          margin-bottom: 0;
        }
      `}
    >
      <Form.Item label="Dates:">
        <RangePicker
          size="large"
          style={{width: '100%'}}
          disabledDate={disabledDate}
          value={[date_from, date_to]}
          ranges={ranges}
          showTime={false}
          format="YYYY/MM/DD"
          onChange={
            (dates, datesString) => {
              onChange('date_from', dates[0])
              onChange('date_to', dates[1])
            }
            // context.onFilterDateChange('dates', dates, datesString)
          }
          // @ts-ignore
          getPopupContainer={trigger => trigger.parentNode}
        />
      </Form.Item>
      {/* {dateRange === 'date_to_date' && <DateToDate />} */}
    </div>
  )
}

export default memo(FilterDateRange)
