/** @jsx jsx */
import {jsx} from '@emotion/core'

import {Report} from './abstract'
import {Fragment} from 'react'
import {Alert, Typography} from 'antd'
import Filter from 'src/filters/filter'
import moment from 'moment'
import {formatDateRange} from 'src/utils/helper'
import FilterDate from '../filters/filter-date'
import * as genericClient from '../utils/generic-api'

const {Title} = Typography

const OPTION_ALL_VALUE = '*';

class PublisherRevenueReport extends Report {
  constructor() {
    super('reports:publisher-revenue', 'Delivery & Revenue', 'revenue', {
      filterDefaults: {
        date_range: 'last_n_days',
        now_minus_n_days_value: 30,
        date_from: moment()
          .subtract(1, 'day')
          .startOf('month'),
        date_to: moment()
          .subtract(1, 'day')
          .endOf('day'),
        site_name: '',
        section_name: '',
      },
      // disableDateRangeOptions: ['last_2_months', 'this_quarter', 'this_year', 'last_week', 'last_year', 'lifetime'],
    })
  }

  static get className() {
    return 'PublisherRevenueReport'
  }

  get orderColumns(): any[] {
    return [
      'date_utc',
      'site_name',
      'section_name',
    ]
  }

  prepareBody({filters, metrics, groupBy}: any) {
    const customFilters: any = []

    if (filters.site_name && filters.site_name !== OPTION_ALL_VALUE) {
      customFilters.push({
        op: '=',
        field: 'site_id',
        value: filters.site_name,
      })
    }
    if (filters.section_name && filters.section_name !== OPTION_ALL_VALUE) {
      customFilters.push({
        op: '=',
        field: 'section_id',
        value: filters.section_name,
      })
    }

    return {
      type: this.id?.split(':')[1], // TODO: Make it better through currentReportType
      filters: customFilters,
      dateRange: formatDateRange(filters.date_range, filters),
      dateGroup: 'lifetime',
    }
  }

  checkIfReportHasDataToFetch(user: any) {
    return true
  }

  async fetchFiltersData(signal: any) {
    let filtersData = {};

    try {
      filtersData = {
        sites: (await genericClient.search('sites', '', signal)).data,
        sections: (await genericClient.search('sections', '', signal)).data,
      };
    } catch (e: any) {
      if (signal.aborted) {
        console.log('[fetchFiltersData] ABORTED!')
      }
    }

    return filtersData;
  }

  renderSidebar({filters, filtersData, onChangeFilter}: any) {
    return (
      <Fragment>
        <Title level={4} style={{marginTop: 20}}>
          General
        </Title>
        <FilterDate
          options={this.options}
          state={filters}
          onChange={onChangeFilter}
        />
        <Filter
          enableOptionAll={true}
          optionAllValue={OPTION_ALL_VALUE}
          label="Sites"
          mode={'default'}
          // url="sites"
          stateKey="site_name"
          state={filters}
          initialLoading={!filtersData.sites}
          dataSource={filtersData.sites}
          onChange={onChangeFilter}
        />
        <Filter
          enableOptionAll={true}
          optionAllValue={OPTION_ALL_VALUE}
          label="Sections"
          mode={'default'}
          // url="sections"
          stateKey="section_name"
          state={filters}
          initialLoading={!filtersData.sections}
          dataSource={filtersData.sections}
          onChange={onChangeFilter}
          onFilter={(item: any) => item.siteId === filters.site_name}
        />
      </Fragment>
    )
  }

  getElementBeforeTable() {
    return <Alert
      message="Important"
      type="warning"
      showIcon
      description={`With regard to finance and billing, please refer only to your Monthly
          PO - as this will be inclusive of any reconciled revenue amounts. The
          report will only display sections for days with data. Revenue is
          displayed in local currency.`}
      style={{marginBottom: 15, minHeight: 104, display: 'grid', alignItems: 'center'}}
    />
  }
}

export default PublisherRevenueReport
