/** @jsx jsx */
import {jsx} from '@emotion/core'

import {Form, TreeSelect} from 'antd'

const data = [
  {
    title: 'All',
    value: 'general',
    key: '0-0',
    children: [
      {
        title: 'Booked Impressions',
        value: 'booked_impressions',
        key: '0-0-0',
      },
      {
        title: 'Displayed Ad Impressions',
        value: 'displayed_impressions',
        key: '0-0-1',
      },
      {
        title: 'Clicks',
        value: 'clicks',
        key: '0-0-2',
      },
      {
        title: 'CTR %',
        value: 'ctr',
        key: '0-0-3',
      },
      {
        title: 'Unique Users',
        value: 'unique_users',
        key: '0-0-4',
      },
    ],
  },
]

interface MetricProps {
  stateKey: string
  state: any
  onChange: any
}

function MetricGeneral({stateKey, state, onChange}: MetricProps) {
  return (
    <Form.Item label="General" style={{marginBottom: 0}}>
      <TreeSelect
        size="large"
        treeData={data}
        value={state[stateKey]}
        onChange={value => onChange(stateKey, value)}
        treeCheckable={true}
        searchPlaceholder={'Please select'}
      />
    </Form.Item>
  )
}

export default MetricGeneral
