import {getUser} from './auth-client'
import {BootstrapAppDataResult, User} from '../types/types'
import {deepClone, isPublisherAccount} from './helper'
// TODO: Load schedules on auth level?

const normalizeUserCampaigns = (user: User) => {
  const userCopy: User = deepClone(user);

  user.accountsIds = [parseInt(userCopy.account.identifier, 10)].concat(
    userCopy.account.subAccounts.map((a: any) => parseInt(a.identifier, 10)),
  )

  let campaigns_raw = userCopy.campaigns;

  if ((!userCopy.campaigns || userCopy.campaigns.length === 0) && (isPublisherAccount(user) || user.allCampaigns)) {
    const { campaigns: account_campaigns } = user.account;

    campaigns_raw = deepClone(account_campaigns);
    if (user.account.subAccounts && user.account.subAccounts.length) {
      user.account.subAccounts.forEach(({ campaigns: subaccount_campaigns }) => {
        campaigns_raw.push(...subaccount_campaigns);
      })
    }
  }

  return Object.assign(user, { campaigns_raw, campaigns: userCopy.campaigns.map((campaign: any) => campaign.id)});
};

const bootstrapAppData = async (): Promise<BootstrapAppDataResult> => {
  let user: User | null = await getUser()
  if (!user) {
      return { user: null };
  }

  normalizeUserCampaigns(user)

  return { user }
}

export {bootstrapAppData}
