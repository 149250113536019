/** @jsx jsx */
import {jsx} from '@emotion/core'

import {Report} from './abstract'
import {Fragment} from 'react'
import {Typography} from 'antd'
import Filter from 'src/filters/filter'
import FilterCampaignStatus from '../filters/filter-campaign-status'
import * as genericClient from '../utils/generic-api'

const {Title} = Typography

const OPTION_ALL_VALUE = '*';

class PublisherCampaignsReport extends Report {
  constructor() {
    super('reports:publisher-campaigns', 'Campaigns', 'publisher-campaigns', {
      filterDefaults: {
        site_name: '',
        section_name: '',
      },
    })
  }

  static get className() {
    return 'PublisherCampaignsReport'
  }

  get orderColumns(): any[] {
    return [
      'section_name',
    ]
  }

  prepareBody({filters, metrics, groupBy}: any) {
    const customFilters = []

    if (filters.site_name && filters.site_name !== OPTION_ALL_VALUE) {
      customFilters.push({
        op: '=',
        field: 'site_id',
        value: filters.site_name,
      })
    }
    if (filters.section_name && filters.section_name !== OPTION_ALL_VALUE) {
      customFilters.push({
        op: '=',
        field: 'section_id',
        value: filters.section_name,
      })
    }
    if (filters.campaign_status && filters.campaign_status.length) {
      customFilters.push({
        op:
          Array.isArray(filters.campaign_status) &&
          filters.campaign_status.length > 1
            ? 'in'
            : '=',
        field: 'status',
        value:
          Array.isArray(filters.campaign_status) &&
          filters.campaign_status.length > 1
            ? filters.campaign_status
            : filters.campaign_status[0],
      })
    }

    const data = {
      type: this.id?.split(':')[1], // TODO: Make it better through currentReportType
      filters: customFilters,
    }

    return data
  }

  checkIfReportHasDataToFetch(user: any) {
    return true
  }

  async fetchFiltersData(signal: any) {
    let filtersData = {};

    try {
      filtersData = {
        sites: (await genericClient.search('sites', '', signal)).data,
        sections: (await genericClient.search('sections', '', signal)).data,
      };
    } catch (e: any) {
      if (signal.aborted) {
        console.log('[fetchFiltersData] ABORTED!')
      }
    }

    return filtersData;
  }

  renderSidebar({filters, filtersData, onChangeFilter}: any) {
    return (
      <Fragment>
        <Title level={4} style={{marginTop: 20}}>
          General
        </Title>
        <FilterCampaignStatus
          state={filters}
          onChange={onChangeFilter}
          dataSource={[
            {id: 'Active', name: 'Active'},
            {id: 'Approved', name: 'Approved'},
            {id: 'Disabled', name: 'Disabled'},
            {id: 'Expired', name: 'Expired'},
          ]}
        />
        <Filter
          enableOptionAll={true}
          optionAllValue={OPTION_ALL_VALUE}
          label="Sites"
          mode={'default'}
          // url="sites"
          stateKey="site_name"
          state={filters}
          initialLoading={!filtersData.sites}
          dataSource={filtersData.sites}
          onChange={(key: any, value: any) => {
            onChangeFilter(key, value)
            onChangeFilter('section_name', '')
          }}
        />
        <Filter
          enableOptionAll={true}
          optionAllValue={OPTION_ALL_VALUE}
          label="Sections"
          mode={'default'}
          // url="sections"
          stateKey="section_name"
          state={filters}
          initialLoading={!filtersData.sections}
          dataSource={filtersData.sections}
          onChange={onChangeFilter}
          onFilter={(item: any) => item.siteId === filters.site_name}
        />
      </Fragment>
    )
  }

  getElementBeforeTable() {
    return null
  }

  generateReports(): void {
    console.log('Generating accounting reports...')
  }
}

export default PublisherCampaignsReport
