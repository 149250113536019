/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Modal} from 'antd'
import * as owasp from 'owasp-password-strength-test'
import moment from 'moment'

import {AuthenticatedUser, UserPermissions} from '../types/types'
import {createInstance, Report} from '../reports/abstract'
import CampaignsSummaryReport from '../reports/campaigns-summary'
import EOCReport from 'src/reports/eoc'
import EOCLiteReport from 'src/reports/eoc-lite'
import PublisherCampaignsReport from 'src/reports/publisher-campaigns'
import PublisherRevenueReport from 'src/reports/publisher-revenue'
import POReport from 'src/reports/po'
import AgencyCampaignsSummaryReport from 'src/reports/agency-campaigns-summary'
import PublisherCampaignsSummaryReport from 'src/reports/publisher-campaigns-summary'
import CreatorCampaignsSummary from 'src/reports/creator-campaigns-summary'
import EventBreakdownReport from 'src/reports/event-breakdown'
import CreatorEventBreakdown from '../reports/creator-event-breakdown'
import ImproveDigitalCampaignsSummary from 'src/reports/improve-digital-campaigns-summary'

import ArcGeneralLogoImage from '../assets/logos/arcplatform/general.png'
import ArcReportsGrayLogoImage from '../assets/logos/arcplatform/reports-gray.png'
import ArcReportsColorLogoImage from '../assets/logos/arcplatform/reports-colour.png'
import ArcReportsWhiteLogoImage from '../assets/logos/arcplatform/reports-white.png'
import ArcStudioGrayLogoImage from '../assets/logos/arcplatform/studio-gray.png'
import ArcStudioColorLogoImage from '../assets/logos/arcplatform/studio-color.png'
import ArcStudioWhiteLogoImage from '../assets/logos/arcplatform/studio-white.png'

import InskinGeneralLogoImage from '../assets/logos/inskinmedia/general.png'
import InskinReportsGrayLogoImage from '../assets/logos/inskinmedia/reports-gray.png'
import InskinReportsColorLogoImage from '../assets/logos/inskinmedia/reports-gray.png'
import InskinReportsWhiteLogoImage from '../assets/logos/inskinmedia/reports-white.png'

import InskinPRMCLogoImage from '../assets/logos/inskinmedia/prmc-gray.png'
import queryString from 'query-string'

const MIN_COLUMN_WIDTH = 100;

owasp.config({ minLength: 8 });

function getAvailableReportClass(id: string, user: any) {
  const fullId = id.includes('reports:') ? id : `reports:${id}`

  switch (fullId) {
    case 'reports:agency-campaigns-summary':
      return createInstance(AgencyCampaignsSummaryReport)
    case 'reports:campaigns-summary':
      return createInstance(CampaignsSummaryReport)
    case 'reports:eoc':
      return createInstance(EOCReport)
    case 'reports:eoc-lite':
      return createInstance(EOCLiteReport)
    case 'reports:event-breakdown':
      return isInskinAccount(user) ? createInstance(EventBreakdownReport) : createInstance(CreatorEventBreakdown)
    case 'reports:publisher-campaigns':
      return createInstance(PublisherCampaignsReport)
    case 'reports:creator-campaigns-summary':
      return createInstance(CreatorCampaignsSummary)
    case 'reports:improve-digital-campaigns-summary':
      return createInstance(ImproveDigitalCampaignsSummary)
    case 'reports:publisher-revenue':
      return createInstance(PublisherRevenueReport)
    case 'reports:po':
      return createInstance(POReport)
    case 'reports:publisher-campaigns-summary':
      return createInstance(PublisherCampaignsSummaryReport)
    default:
      return
  }
}

function getAvailableReportsClass(user: Partial<AuthenticatedUser>) {
  return user.permissions?.reduce((result: Report[], id) => {
    if (id.includes('reports:')) {
      switch (id) {
        case 'reports:campaigns-summary':
          result.push(createInstance(CampaignsSummaryReport));
          break;
        case 'reports:agency-campaigns-summary':
          result.push(createInstance(AgencyCampaignsSummaryReport));
          break;
        case 'reports:publisher-campaigns-summary':
          result.push(createInstance(PublisherCampaignsSummaryReport));
          break;
        case 'reports:creator-campaigns-summary':
          result.push(createInstance(CreatorCampaignsSummary));
          break;
        case 'reports:improve-digital-campaigns-summary':
          result.push(createInstance(ImproveDigitalCampaignsSummary));
          break;
        case 'reports:eoc':
          result.push(createInstance(EOCReport));
          break;
        case 'reports:eoc-lite':
          result.push(createInstance(EOCLiteReport));
          break;
        case 'reports:publisher-campaigns':
          result.push(createInstance(PublisherCampaignsReport));
          break;
        case 'reports:publisher-revenue':
          result.push(createInstance(PublisherRevenueReport));
          break;
        case 'reports:po':
          result.push(createInstance(POReport));
          break;
        case 'reports:event-breakdown':
          result.push(isInskinAccount(user) ? createInstance(EventBreakdownReport) : createInstance(CreatorEventBreakdown));
          break;
      }
    }
    return result
  }, [])
}

function isInskinAccount(user: Partial<AuthenticatedUser>) {
  return Boolean(user.account?.type.includes('owner') || user.isWhitelistedInskinUser);
}

function isPublisherAccount(user: Partial<AuthenticatedUser>) {
  return Boolean(user.account?.type.includes('publisher'))
}

function isAgencyAccount(user: Partial<AuthenticatedUser>) {
  return Boolean(user.account?.type.includes('agency'))
}

function isSchedulerEnabled(user: Partial<AuthenticatedUser>) {
  return hasPermissions(user, ['schedules:write'])
}

function hasPermissions(
  user: Partial<AuthenticatedUser>,
  permissions: UserPermissions[],
) {
  return user.permissions?.includes('*') || permissions.every(permission => user.permissions?.includes(permission))
}

function onOpenURL(url: string) {
  window.open(url, '_blank')
}

function formatDateRange(type: string, filters: any) {
  const dateRange = {
    type,
  } as any

  switch (type) {
    case 'last_2_months':
      dateRange.n = 60
      dateRange.type = 'last_n_days'
      break;
    case 'last_n_days':
      dateRange.n = filters.now_minus_n_days_value
      dateRange.type = 'last_n_days'
      break;
    case 'date_to_date':
      dateRange.start = filters.date_from.startOf('day').toISOString(true)
      dateRange.end = filters.date_to.endOf('day').toISOString(true)
      dateRange.type = 'date_to_date'
      break;
  }

  return dateRange
}

const compareByAlph = (a: string|null|undefined, b: string|null|undefined) => (a || '').localeCompare(b || '');

const compareSettings = (a: any, b: any, settingName: string, typeCastFunc: any = (val: any) => Number(val)) => {
  let found = a && a.settings && a.settings.find((s: any) => s.key === settingName);
  const valueA = found ? (found.value === '1') : false;
  found = b && b.settings && b.settings.find((s: any) => s.key === settingName);
  const valueB = found ? (found.value === '1') : false;

  return typeCastFunc(valueA) - typeCastFunc(valueB);
};

const columnSorter = (column: any, a: any, b: any, normalizer? : any) => {
  let normalized_a = (!a[column.field] && a[column.field] !== 0) ? undefined : (a[column.field].value ? a[column.field].value : a[column.field])
  let normalized_b = (!b[column.field] && b[column.field] !== 0) ? undefined : (b[column.field].value ? b[column.field].value : b[column.field])

  if (normalizer) {
    normalized_a = normalizer(normalized_a)
    normalized_b = normalizer(normalized_b)
  }

  if (typeof normalized_a === 'string' && /\d{2}\/\d{2}\/\d{4}/.test(normalized_a) && moment(normalized_a, 'DD/MM/YYYY').isValid()) {
    normalized_a = Date.parse(moment(normalized_a, 'DD/MM/YYYY').toString());
  }
  if (typeof normalized_b === 'string' && /\d{2}\/\d{2}\/\d{4}/.test(normalized_b) && moment(normalized_b, 'DD/MM/YYYY').isValid()) {
    normalized_b = Date.parse(moment(normalized_b, 'DD/MM/YYYY').toString());
  }
  if (typeof normalized_a === 'string') {
    normalized_a = normalized_a.toLowerCase();
  }
  if (typeof normalized_b === 'string') {
    normalized_b = normalized_b.toLowerCase();
  }
  if (normalized_a instanceof Date) {
    normalized_a = normalized_a.toISOString();
  }
  if (normalized_b instanceof Date) {
    normalized_b = normalized_b.toISOString();
  }

  if (typeof normalized_a === 'number' && typeof normalized_b === 'number') {
    return normalized_a - normalized_b
  }

  const sorted = [normalized_a, normalized_b].sort();

  return sorted.indexOf(normalized_a) - sorted.indexOf(normalized_b)
}

const capitalize = (s: string) => {
  if (typeof s !== 'string') {
    return s
  }

  return s.toLowerCase().charAt(0).toUpperCase() + s.toLowerCase().slice(1)
}

const errorMessage = (
  message: string = '',
  title: string = 'Error occurred. Please, refresh the page and try again.',
) => {
  return {
    title,
    content: message.split('|||').map((m: any, index: number) => {
      return <div key={index}>{m}</div>
    }),
  }
}

function deepClone(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email.toLowerCase());
}

function normalizeAccounts(accounts: any[]) {
  return accounts.map((item: any) => {
    if (item.subAccounts && item.subAccounts.length) {
      item.subAccounts = item.subAccounts.map((subAccount: any) => subAccount.id);
    }

    return item;
  }).sort((a: any,b: any) =>
    a.type.localeCompare(b.type)
    || a.name.localeCompare(b.name)
    || (typeof a.id === 'string' && a.id.localeCompare(b.id))
    || (typeof a.id === 'number' && a.id >b.id)
  );
}

function shallowEqual(obj1: any, obj2: any) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

const validatePassword = (password: string) => {
  const testResult = owasp.test(password);

  if (testResult.strong) {
    return true;
  }

  Modal.error(errorMessage(testResult.errors.join('|||'), 'Weak password!'));
  return false;
};

const dayOfWeekNormalizer = (weekDay: string) => ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
  .indexOf(weekDay.substr(0,3).toLocaleLowerCase())

const monthNormalizer = (monthName: string) => {
  const d = Date.parse(`${monthName} 1, ${new Date().getFullYear()}`);

  return isNaN(d) ? -1: new Date(d).getMonth() + 1
}

const getMaxColumns = () => {
  return Math.floor((window.innerWidth - 140) / MIN_COLUMN_WIDTH)
}

const getLogo = (
  app = 'reports',
  domain = 'inskinmedia',
  color = 'gray'
) => {
  switch (domain) {
    case 'inskinmedia':
      switch (app) {
        case 'tools':
        case 'auth':
          return InskinGeneralLogoImage;
        case 'reports':
          switch (color) {
            case 'gray':
              return InskinReportsGrayLogoImage;
            case 'color':
              return InskinReportsColorLogoImage;
            case 'white':
              return InskinReportsWhiteLogoImage;
          }
          break;
        case 'studio':
        case 'creator':
          switch (color) {
            case 'gray':
              return ArcStudioGrayLogoImage;
            case 'white':
              return  ArcStudioWhiteLogoImage;
            case 'color':
              return ArcStudioColorLogoImage;
          }
          break;
        case 'prmc':
          return InskinPRMCLogoImage;
      }
      return InskinGeneralLogoImage;
    case 'arcplatform':
      switch (app) {
        case 'tools':
        case 'auth':
          return ArcGeneralLogoImage;
        case 'reports':
          switch (color) {
            case 'gray':
              return ArcReportsGrayLogoImage;
            case 'color':
              return ArcReportsColorLogoImage
            case 'white':
              return ArcReportsWhiteLogoImage;
          }
          break;
        case 'studio':
        case 'creator':
          switch (color) {
            case 'gray':
              return ArcStudioGrayLogoImage;
            case 'white':
              return  ArcStudioWhiteLogoImage;
            case 'color':
              return ArcStudioColorLogoImage;
          }
          break;
        case 'prmc':
          return InskinPRMCLogoImage;
      }
      return ArcGeneralLogoImage;
  }

  return InskinGeneralLogoImage;
}

const getSiteTitle = (app: string|null|undefined = undefined, domain: string|null|undefined = undefined ) => {
  if (domain === 'acp') {
    return 'ACP'
  }
  const hostnameArr = ((queryString.parse(window.location.search) || {}).redirect || document.location.hostname).toString().split('.')

  app = app || hostnameArr[0];
  domain = domain || hostnameArr[hostnameArr.length - 2]

  switch (app) {
    case 'tools':
      switch (domain) {
        case 'inskinmedia':
          return 'Inskin Tools';
        case 'arcplatform':
          return 'Arc Tools';
        default:
          return 'Tools'
      }
    case 'auth':
      switch (domain) {
        case 'inskinmedia':
          return 'Inskin Auth';
        case 'arcplatform':
          return 'Arc Auth';
        default:
          return 'Auth'
      }
    case 'reports':
      switch (domain) {
        case 'inskinmedia':
          return 'Inskin Reports';
        case 'arcplatform':
          return 'Arc Reports';
        default:
          return 'Reports'
      }
    case 'studio':
    case 'creator':
      return 'Arc Studio';
    case 'prmc':
      return 'Inskin PO Reports Management Console';
    default:
      return 'Inskin'
  }
}

const commonValidateFiltersInputRules = (args: any): boolean | string[] => {
  const { filters } = args;

  if (filters.date_range === 'last_n_days' && !filters.now_minus_n_days_value) {
    return ['Please input the number of days!'];
  }

  return true;
}

const getDefaultCampaignsFilterAsyncOnFilter = (filters: any) => {
  return (item: any) => {
    let result = true;

    if (filters.agencies && filters.agencies.length) {
      result = filters.agencies.includes(parseInt(item.agencyId));
    }

    if (result && filters.advertisers && filters.advertisers.length) {
      result = filters.advertisers.includes(parseInt(item.advertiserId));
    }

    if (result && filters.publishers && filters.publishers.length) {
      result = filters.publishers.includes(parseInt(item.publisherId));
    }

    return result;
  }
}

export {
  getAvailableReportClass,
  getAvailableReportsClass,
  isInskinAccount,
  isPublisherAccount,
  isAgencyAccount,
  isSchedulerEnabled,
  hasPermissions,
  onOpenURL,
  formatDateRange,
  compareByAlph,
  compareSettings,
  columnSorter,
  capitalize,
  errorMessage,
  deepClone,
  validateEmail,
  normalizeAccounts,
  shallowEqual,
  validatePassword,
  dayOfWeekNormalizer,
  monthNormalizer,
  getMaxColumns,
  getLogo,
  getSiteTitle,
  commonValidateFiltersInputRules,
  getDefaultCampaignsFilterAsyncOnFilter
}
