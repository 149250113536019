/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useState} from 'react'
import {Button, Modal} from 'antd'
import * as reportClient from '../utils/report-client'
import {capitalize, errorMessage} from '../utils/helper'

function ExportButton (props: any) {
  const { reportFilter, user, reportInstance } = props;
  const [processing, setProcessing] = useState(false);

  return <div onClick={(e) => e.stopPropagation()}>
    <Button
      type={'primary'}
      icon={'export'}
      size={'small'}
      loading={processing}
      onClick={() => {
        setProcessing(true);

        const data = reportInstance?.prepareBody({
          filters: { date_range: 'lifetime', ...reportFilter },
          metrics: reportInstance.options.metricDefaults,
          groupBy: reportInstance.options.groupByDefaults,
          user
        });

        return reportClient
          .create(data)
          .then(({ id }) => {
            const interval = setInterval(() => reportClient
              .findOne(id)
              .then((response: any) => {
                switch (response?.status) {
                  case 'processing':
                    break;
                  case 'failed':
                    clearInterval(interval);
                    setProcessing(false);

                    const errors = [''];
                    if (response.errors && response.errors.length) {
                      response.errors.forEach((e: any) => {
                        errors.push(capitalize(e.reason));
                      })
                      return Modal.error(errorMessage(errors.join('|||')));
                    }
                    Modal.error(errorMessage());
                    break;
                  case 'complete':
                    clearInterval(interval);
                    setProcessing(false);
                    reportInstance.onExport(response.id, 'xlsx');
                }
              }), 1000);


          })
          .catch(e => {
            console.log('export:error', e);
            setProcessing(false)
          });
      }}
    >
      Export Report
    </Button>
  </div>;
}

export default ExportButton;